import {Component, Input, Output, EventEmitter, ElementRef} from '@angular/core';

/**
 * `<app-message-prompt>` adds prompt messages.
 *
 * @example
 *   <app-message-prompt
 *     [message]="message"
 *     [type]="info"
 *     (promptCloseEvent)="closePromptAction($event)"
 *   </app-message-prompt>
 *
 * @param message - message to show within prompt box
 * @param type - info|danger|warn|success default is info
 * @param promptCloseEvent - event that is to be emitted when close button is clicked.
 */
@Component({
    selector: 'app-message-prompt',
    templateUrl: './message-prompt.component.html',
    styleUrls: ['./message-prompt.component.scss']
})
export class MessagePromptComponent {

    @Input() message: string;
    /**
     * Possible values are: info, danger, warn, success
     */
    @Input() type: string;
    @Output() promptCloseEvent = new EventEmitter();

    get promptClass(): string {
        let className = 'message-prompt ';
        switch (this.type) {
            case 'success':
                className += ' success';
                break;
            case 'warn':
                className += 'warn';
                break;
            case 'danger':
                className += 'danger';
                break;
            default:
                className += 'info';
                break;
        }
        return className;
    }

    get iconBackground(): string {
        let className = '';
        switch (this.type) {
            case 'success':
                className += ' success';
                break;
            case 'warn':
                className += 'background-warn';
                break;
            case 'danger':
                className += 'danger';
                break;
            default:
                className += 'info';
                break;
        }
        return className;
    }

    get iconClass(): string {
        let className = '';
        switch (this.type) {
            case 'success':
                className += ' success';
                break;
            case 'warn':
                className += 'sif sif-warning';
                break;
            case 'danger':
                className += 'danger';
                break;
            default:
                className += 'info';
                break;
        }
        return className;
    }


    constructor(private host: ElementRef<HTMLElement>) {
    }

  onClose(): void {
    if (this.promptCloseEvent.observers.length > 0) {
      this.promptCloseEvent.emit(this.message);
    }
    this.host.nativeElement.remove();
  }

}
