import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, CanDeactivate, Router} from '@angular/router';
import {OptimizationReport} from '../../../../models/optimization-report.model';
import {OptimizationReportService} from '../../../../services/optimization-report.service';
import {ModelRunService} from '../../../../services/model-run.service';
import {ModelRun} from '../../../../models/model-run.model';
import {Scenario} from '../../../../models/scenario.model';
import {ScenarioGroup} from '../../../../models/scenario-group.model';
import {SkuConfig} from '../../../../models/sku-config.model';
import {MetaData} from '../../../../models/meta-data.model';
import {ScenarioService} from '../../../../services/scenario.service';
import {FormControl, Validators} from '@angular/forms';
import {ReadFile} from 'ngx-file-helpers';
import {UiBlockerService} from '../../../../services/ui-blocker.service';
import {SnackBarService} from '../../../../components/snack-bar/snack-bar.service';
import {OptimizationRule} from '../../../../models/optimization-rule.model';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ReportService} from '../../../../services/report.service';
import {GenericConfirmationModalComponent} from '../../../../components/generic-confirmation-modal/generic-confirmation-modal.component';
import {ScenarioGroupService} from '../../../../services/scenario-group.service';
import {OptimizationReportSkuConfig} from '../../../../interfaces/optimization-report.interface';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {
  GroupBasedScenarioFilterData,
  NonGroupBasedScenarioFilterData
} from '@app/interfaces/scenario-group-filter-data.interface';
import {ModelRunSku} from '@app/models/model-run-sku.model';
import {SimulationRunInputService} from '@app/services/simulation-run-input.service';
import {SimulationRunInput} from '@app/models/simulation-run-input.model';
import {ReportOutputMetric} from '@app/interfaces/report-output-metric.interface';
import {AppConstantsService} from '@app/services/app-constants.service';

@Component({
  selector: 'app-create-optimization-report',
  templateUrl: './create-optimization-report.component.html',
  styleUrls: ['./create-optimization-report.component.scss']
})

export class CreateOptimizationReportComponent implements OnInit, CanDeactivate<boolean> {

  report: OptimizationReport;

  editOptimizationReportName: boolean;

  modelRun: ModelRun;

  projectId: number;

  modelRunId: string;

  validationErrors: Record<string, string>;

  metaData: MetaData;

  scenarios: Array<Scenario>;

  scenarioGroups: Array<ScenarioGroup>;

  modelRunSkus: Array<ModelRunSku>;

  selectedScenarioId: string;

  skuConfigs: Array<SkuConfig>;

  metrics: Array<ReportOutputMetric>;

  optimizationSkuConfigs: Array<OptimizationReportSkuConfig>;

  competingSkuConfigs: Array<OptimizationReportSkuConfig>;

  lockedSkuConfigs: Array<OptimizationReportSkuConfig>;

  optimizationMethods: Array<string> = ['Fast', 'Exhaustive Search'];

  selectedOptimizationSkuConfigs: Set<OptimizationReportSkuConfig>;

  optimizationRule: OptimizationRule;

  exitCallbackFunction: () => void;

  disableStartRun: boolean;

  scenarioGroupsData: Array<GroupBasedScenarioFilterData>;

  referenceScenarioData: NonGroupBasedScenarioFilterData;

  searchValue = '';

  completeAndOptimizeAllSelected = false;

  completeAndOptimizeIndeterminate = false;

  lockInAndOptimizeAllSelected = false;

  lockInAndOptimizeIndeterminate = false;

  reportNameMaxLength = 25;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private reportService: ReportService,
              private optimizationReportService: OptimizationReportService,
              private modelRunService: ModelRunService,
              private scenarioService: ScenarioService,
              private scenarioGroupService: ScenarioGroupService,
              private uiBlockerService: UiBlockerService,
              private snackBarService: SnackBarService,
              private dialog: MatDialog,
              private simulationRunInputService: SimulationRunInputService,
              private appConstantsService: AppConstantsService) {
  }

  setupNewReport(): OptimizationReport {
    const optimizationReport = new OptimizationReport();
    optimizationReport.name = this.optimizationReportService.getNewReportName(this.reportNameMaxLength);
    optimizationReport.description = '';
    optimizationReport.projectId = this.projectId;
    optimizationReport.modelRunId = this.modelRunId;
    optimizationReport.outputConfigMetric = '';
    return optimizationReport;
  }

  duplicateReport(sourceReport: OptimizationReport): OptimizationReport {
    const report = new OptimizationReport();
    report.name = this.optimizationReportService.getDuplicateReportName(sourceReport.name, this.reportNameMaxLength);
    report.description = sourceReport.description;
    report.projectId = sourceReport.projectId;
    report.modelRunId = sourceReport.modelRunId;
    report.scenarioId = this.scenarios.find(it => it.id === sourceReport.scenarioId) ? sourceReport.scenarioId : null;
    report.outputConfigMetric = sourceReport.outputConfigMetric;
    report.method = sourceReport.method;
    report.rules = sourceReport.rules;
    report.competeSkuConfigs = sourceReport.competeSkuConfigs || [];
    report.lockInSkuConfigs = sourceReport.lockInSkuConfigs || [];
    report.minNoOfItems = sourceReport.minNoOfItems;
    report.maxNoOfItems = sourceReport.maxNoOfItems;
    report.noOfCombinations = sourceReport.noOfCombinations;
    return report;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const source = params.source;
      this.reportNameMaxLength = 25;
      this.optimizationRule = null;
      this.validationErrors = {};
      this.disableStartRun = true;
      this.selectedOptimizationSkuConfigs = new Set();
      this.modelRun = this.route.parent.parent.snapshot.data.modelRun;
      this.modelRunSkus = this.route.parent.parent.snapshot.data.modelRunSkus;
      this.projectId = this.modelRun.projectId;
      this.modelRunId = this.modelRun.id;
      this.editOptimizationReportName = false;
      this.scenarios = this.scenarioService.scenarios;
      this.scenarioGroups = this.scenarioGroupService.scenarioGroups;
      this.metaData = this.route.parent.parent.snapshot.data.metaData;
      this.metrics = Object.keys(this.metaData.outputConfigurations).map((key: string) => {
        return {id: key, data: this.metaData.outputConfigurations[key]};
      });
      window.addEventListener('click', (event) => {
        this.onClickOutsideSkuConfigTable(event);
      });
      this.prepareScenarioGroups('');
      // setup new optimization report
      this.report = this.setupNewReport();
      if (source) {
        this.optimizationReportService.getById(source, {
          projectId: this.projectId,
          modelRunId: this.modelRunId
        }).subscribe((sourceReport: OptimizationReport) => {
          this.report = this.duplicateReport(sourceReport);

          const competeSkuConfigs = this.report.competeSkuConfigs;
          const lockInSkuConfigs = this.report.lockInSkuConfigs;
          const minNoOfItems = this.report.minNoOfItems;
          const maxNoOfItems = this.report.maxNoOfItems;
          const noOfCombinations = this.report.noOfCombinations;

          this.changeScenario(this.report.scenarioId).then(() => {
            competeSkuConfigs.forEach(it => this.onSkuConfigCompeteToggle(it));
            lockInSkuConfigs.forEach(it => this.onSkuConfigLockInToggle(it));
            this.report.minNoOfItems = minNoOfItems;
            this.report.maxNoOfItems = maxNoOfItems;
            this.report.noOfCombinations = noOfCombinations;

            if (this.report.rules) {
              this.optimizationRule = new OptimizationRule();
              this.optimizationRule.projectId = this.projectId;
              this.optimizationRule.modelRunId = this.modelRunId;
              this.optimizationRule.fileName = `Copied from ${sourceReport.name}`;
            }
            this.validateModelProperty('minNoOfItems');
            this.validateModelProperty('maxNoOfItems');
            this.validateModelProperty('noOfCombinations');
            this.toggleStartRunButton();
          }).catch(() => {
            //do nothing;
          });

        });
      }
    });
  }

  search(searchValue: string): void {
    this.searchValue = searchValue;
    this.prepareScenarioGroups(this.searchValue);
  }

  prepareScenarioGroups(filterString: string): void {
    const dropDownScenarioGroups = this.scenarioGroupService.prepareScenarioGroups(this.scenarios, this.scenarioGroups, filterString);
    this.referenceScenarioData = dropDownScenarioGroups.referenceScenario;
    this.scenarioGroupsData = dropDownScenarioGroups.scenarioGroupsData;
  }

  /**
   * Detects if a click action was triggered outside of 'selectable-table' and if so resets the
   * selected optimization sku configs.
   */
  onClickOutsideSkuConfigTable(event: MouseEvent): void {
    const container = document.getElementsByClassName('selectable-table')[0];
    if (container) {
      if (container !== event.target && !container.contains(event.target as Element)) {
        // if clicking outside of table then unselect all the selected items
        this.selectedOptimizationSkuConfigs = new Set();
      } else {
        // if clicking inside of table then unselect all the selected items if clicking on unselected rows
        if (!(event.ctrlKey || event.metaKey || event.shiftKey)) {
          let clickedOnSelectedRow = false;
          this.selectedOptimizationSkuConfigs.forEach((skuConfig) => {
            const selectedRow = document.getElementById(`${skuConfig.skuId}`);
            clickedOnSelectedRow = clickedOnSelectedRow || (selectedRow === event.target || selectedRow.contains(event.target as Element));
          });
          if (!clickedOnSelectedRow) {
            this.selectedOptimizationSkuConfigs = new Set();
          }
        }
      }
    }
  }

  toggleOptimizationReportNameEdit(): void {
    this.editOptimizationReportName = !this.editOptimizationReportName;
  }

  closeCreateOptimizationReport(): void {
    this.router.navigate(['list'], {relativeTo: this.route.parent});
  }

  changeScenario(scenarioId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      if (scenarioId && this.scenarios.find(it => it.id === scenarioId)) {
        this.uiBlockerService.block();
        this.selectedScenarioId = scenarioId;
        this.report.scenarioId = scenarioId;
        this.report.competeSkuConfigs = [];
        this.report.lockInSkuConfigs = [];
        this.report.minNoOfItems = undefined;
        this.report.maxNoOfItems = undefined;
        this.report.noOfCombinations = 1;
        this.completeAndOptimizeAllSelected = false;
        this.completeAndOptimizeIndeterminate = false;
        this.lockInAndOptimizeAllSelected = false;
        this.lockInAndOptimizeIndeterminate = false;
        this.simulationRunInputService.fetchAll(this.projectId, this.modelRunId, this.selectedScenarioId).subscribe((simulationRunInputs: SimulationRunInput[]) => {
          this.skuConfigs = this.scenarioService.generateSkuConfigs(this.modelRunSkus, simulationRunInputs, []).filter(it => it.isSelected);
          this.optimizationSkuConfigs = this.skuConfigs.reduce((acc: Array<OptimizationReportSkuConfig>, skuConfig: SkuConfig) => {
              acc.push({
                skuConfigId: skuConfig.id,
                compete: false,
                lockIn: false,
                skuId: skuConfig.skuId,
                name: skuConfig.name,
                reportingName: skuConfig.reportingName
              });
            return acc;
          }, []);
          this.uiBlockerService.unblock();
          resolve(scenarioId);
        });
        this.toggleStartRunButton();
      } else {
        reject();
      }
    });
  }

  updateMinNoOfItems(): void {
    if (this.lockedSkuConfigsLength) {
      this.report.minNoOfItems = this.lockedSkuConfigsLength;
    } else if (this.competingSkuConfigsLength) {
      this.report.minNoOfItems = 1;
    } else {
      this.report.minNoOfItems = 0;
    }
  }

  toggleCompleteAndOptimizeAllSelected(event: MatCheckboxChange): void {
    this.optimizationSkuConfigs.forEach((optimizationSkuConfig) => {
      optimizationSkuConfig.compete = !event.checked;
      this.onSkuConfigCompeteToggle(optimizationSkuConfig.skuId);
    });
  }

  checkCompleteAndOptimizeAllSelected(): void {
    const totalSelected = this.optimizationSkuConfigs.filter((optimizationSkuConfig) => {
      return optimizationSkuConfig.compete;
    }).length;
    const totalCount = this.optimizationSkuConfigs.length;
    if (totalSelected === 0) {
      this.completeAndOptimizeAllSelected = false;
      this.completeAndOptimizeIndeterminate = false;
    } else if (totalSelected > 0 && totalSelected < totalCount) {
      this.completeAndOptimizeAllSelected = false;
      this.completeAndOptimizeIndeterminate = true;
    } else if (totalSelected === totalCount) {
      this.completeAndOptimizeAllSelected = true;
      this.completeAndOptimizeIndeterminate = false;
    }
  }

  onSkuConfigCompeteToggle(skuId: number): void {
    const optimizationSkuConfigData = this.optimizationSkuConfigs.find(it => it.skuId === skuId);
    if (optimizationSkuConfigData) {
      const toggle = (optimizationSkuConfig) => {
        optimizationSkuConfig.compete = !optimizationSkuConfig.compete;
        if (optimizationSkuConfig.compete) {
          this.report.competeSkuConfigs.push(optimizationSkuConfig.skuId);
        } else {
          this.report.competeSkuConfigs.splice(this.report.competeSkuConfigs.indexOf(optimizationSkuConfig.skuId), 1);
          optimizationSkuConfig.lockIn = false;
          this.onSkuConfigLockInToggle(optimizationSkuConfig);
        }
      };

      if (this.selectedOptimizationSkuConfigs.has(optimizationSkuConfigData)) {
        this.selectedOptimizationSkuConfigs.forEach((optimizationSkuConfig) => {
          toggle(optimizationSkuConfig);
        });
      } else {
        toggle(optimizationSkuConfigData);
      }

      this.competingSkuConfigs = this.optimizationSkuConfigs.filter((config) => config.compete);
      this.updateMinNoOfItems();
      if (this.report.maxNoOfItems) {
        this.validateModelProperty('maxNoOfItems');
      }
      this.checkCompleteAndOptimizeAllSelected();
      this.checkLockInAndOptimizeAllSelected();
    }
  }

  toggleLockInAndOptimizeAllSelected(event: MatCheckboxChange): void {
    this.optimizationSkuConfigs.forEach((optimizationSkuConfig) => {
      if (optimizationSkuConfig.compete) {
        optimizationSkuConfig.lockIn = !event.checked;
        this.onSkuConfigLockInToggle(optimizationSkuConfig.skuId);
      }
    });
    this.checkLockInAndOptimizeAllSelected();
  }

  checkLockInAndOptimizeAllSelected(): void {
    const totalLockInSelected = this.optimizationSkuConfigs.filter((optimizationSkuConfig) => {
      return optimizationSkuConfig.lockIn;
    }).length;
    const totalCompleteSelected = this.optimizationSkuConfigs.filter((optimizationSkuConfig) => {
      return optimizationSkuConfig.compete;
    }).length;
    if (totalLockInSelected === 0) {
      this.lockInAndOptimizeAllSelected = false;
      this.lockInAndOptimizeIndeterminate = false;
    } else if (totalLockInSelected > 0 && totalLockInSelected < totalCompleteSelected) {
      this.lockInAndOptimizeAllSelected = false;
      this.lockInAndOptimizeIndeterminate = true;
    } else if (totalLockInSelected === totalCompleteSelected) {
      this.lockInAndOptimizeAllSelected = true;
      this.lockInAndOptimizeIndeterminate = false;
    }
  }

  onSkuConfigLockInToggle(skuId: number): void {
    const optimizationSkuConfigData = this.optimizationSkuConfigs.find(it => it.skuId === skuId);
    if (optimizationSkuConfigData) {
      const toggle = (optimizationSkuConfig) => {
        optimizationSkuConfig.lockIn = !optimizationSkuConfig.lockIn;
        if (optimizationSkuConfig.lockIn) {
          this.report.lockInSkuConfigs.push(optimizationSkuConfig.skuId);
        } else {
          this.report.lockInSkuConfigs.splice(this.report.lockInSkuConfigs.indexOf(optimizationSkuConfig.skuId), 1);
        }
      };
      if (this.selectedOptimizationSkuConfigs.has(optimizationSkuConfigData)) {
        this.selectedOptimizationSkuConfigs.forEach((optimizationSkuConfig) => {
          toggle(optimizationSkuConfig);
        });
      } else {
        toggle(optimizationSkuConfigData);
      }

      this.lockedSkuConfigs = this.optimizationSkuConfigs.filter((config) => config.lockIn);
      this.updateMinNoOfItems();
      if (this.report.maxNoOfItems) {
        this.validateModelProperty('maxNoOfItems');
      }
      this.checkLockInAndOptimizeAllSelected();
    }
  }

  get competingSkuConfigsLength(): number {
    return this.competingSkuConfigs ? this.competingSkuConfigs.length : 0;
  }

  get lockedSkuConfigsLength(): number {
    return this.lockedSkuConfigs ? this.lockedSkuConfigs.length : 0;
  }

  onOptimizationMethodsChange(method: string): void {
    this.report.method = method;
  }

  onMetricChange(outputConfigMetric: string): void {
    this.report.outputConfigMetric = outputConfigMetric;
    this.toggleStartRunButton();
  }

  validateModelReportName(): string {
    const maxLength = this.reportNameMaxLength;
    const name = this.report.name;
    const cachedOptimizationReports = this.optimizationReportService.getAll(this.projectId, this.modelRunId);
    const preExistingReportWithSameName = cachedOptimizationReports.find((report: OptimizationReport) => {
      return report.name.toLowerCase() === name.toLowerCase();
    });
    if (preExistingReportWithSameName) {
      return this.appConstantsService.REPORT_ALREADY_EXISTS;
    } else {
      const validate = new FormControl(name, [
        Validators.required,
        Validators.maxLength(maxLength)
      ]);
      return validate.invalid ? validate.errors.required ? `Report name is required.` : `The maximum number of characters allowed is ${maxLength}.` : null;
    }
  }

  validateModelReportDescription(): string {
    let reportDescriptionError = null;
    const reportDescriptionMaxLength = 50;
    const description = this.report.description;
    if (description && description.length > reportDescriptionMaxLength) {
      reportDescriptionError = `The maximum number of characters allowed is ${reportDescriptionMaxLength}.`;
    }
    return reportDescriptionError;
  }

  validateModelOutputConfigMetric(): string {
    const validate = new FormControl(this.report.outputConfigMetric, [
      Validators.required
    ]);
    return validate.invalid ? 'Metric is required.' : null;
  }

  validateModelMinNoOfItems(): string {
    const minNoOfItems = this.report.minNoOfItems;
    const min = this.lockedSkuConfigsLength;
    const max = this.competingSkuConfigsLength;
    const validate = new FormControl(minNoOfItems, [
      Validators.required,
      Validators.pattern(/^\d+$/),
      Validators.min(min),
      Validators.max(max)
    ]);
    return validate.invalid ? `Please enter a value between ${min} and ${max}.` : null;
  }

  validateModelMaxNoOfItems(): string {
    const maxNoOfItems = this.report.maxNoOfItems;
    const min = this.report.minNoOfItems;
    const max = this.competingSkuConfigsLength;
    const validate = new FormControl(maxNoOfItems, [
      Validators.required,
      Validators.pattern(/^\d+$/),
      Validators.min(min),
      Validators.max(max)
    ]);
    return validate.invalid ? `Please enter a value between ${min} and ${max}.` : null;
  }


  validateModelNoOfCombinations(): string {
    const noOfCombinations = this.report.noOfCombinations;
    const validate = new FormControl(noOfCombinations, [
      Validators.required,
      Validators.pattern(/^\d+$/),
      Validators.min(1)
    ]);
    return validate.invalid ? `Please enter a value greater than 0.` : null;
  }

  validateModelScenario(): string {
    const validate = new FormControl(this.report.scenarioId, [
      Validators.required
    ]);
    return validate.invalid ? 'Scenario is required.' : null;
  }

  validateModelCompeteSkuConfigs(): string {
    return this.report.competeSkuConfigs.length === 0 ? 'Please select at least one competing sku' : null;
  }

  validateModelProperty(modelFieldName: string): boolean {
    const fieldToValidatorMapping = {
      name: this.validateModelReportName,
      description: this.validateModelReportDescription,
      minNoOfItems: this.validateModelMinNoOfItems,
      maxNoOfItems: this.validateModelMaxNoOfItems,
      noOfCombinations: this.validateModelNoOfCombinations,
    };

    if (fieldToValidatorMapping[modelFieldName]) {
      this.validationErrors[modelFieldName] = fieldToValidatorMapping[modelFieldName].call(this);
      this.toggleStartRunButton();
      return this.validationErrors.name === null;
    }
    return false;
  }

  toggleStartRunButton(): void {
    const reportNameError = this.validateModelReportName();
    const reportDescriptionError = this.validateModelReportDescription();
    const metricError = this.validateModelOutputConfigMetric();
    const scenarioError = this.validateModelScenario();
    const competeSkuConfigsError = this.validateModelCompeteSkuConfigs();
    const minNoOfItemsError = this.validateModelMinNoOfItems();
    const maxNoOfItemsError = this.validateModelMaxNoOfItems();
    const noOfCombinationsError = this.validateModelNoOfCombinations();

    this.disableStartRun = (reportNameError || reportDescriptionError || scenarioError || competeSkuConfigsError ||
      minNoOfItemsError || maxNoOfItemsError || noOfCombinationsError ||
      metricError) !== null;

  }

  onSkuConfigRowClick(event: MouseEvent, optimizationSkuConfig: OptimizationReportSkuConfig): void {
    const selectSingleRow = (optSkuConfig: OptimizationReportSkuConfig): void => {
      const alreadySelected = this.selectedOptimizationSkuConfigs.has(optSkuConfig);
      if (alreadySelected) {
        this.selectedOptimizationSkuConfigs.delete(optSkuConfig);
      } else {
        this.selectedOptimizationSkuConfigs.add(optSkuConfig);
      }
    };
    if (event.ctrlKey || event.metaKey) {
      selectSingleRow(optimizationSkuConfig);
    } else if (event.shiftKey) {
      const lastSelectedItem = this.selectedOptimizationSkuConfigs ? [...this.selectedOptimizationSkuConfigs].pop() : null;
      const currentIndex = this.optimizationSkuConfigs.indexOf(optimizationSkuConfig);
      const lastSelectedItemIndex = this.optimizationSkuConfigs.indexOf(lastSelectedItem);
      let startIndex = null;
      let endIndex = null;
      if (currentIndex > lastSelectedItemIndex) {
        startIndex = lastSelectedItemIndex === -1 ? currentIndex : lastSelectedItemIndex + 1;
        endIndex = currentIndex;
      } else {
        startIndex = currentIndex;
        endIndex = lastSelectedItemIndex === -1 ? currentIndex : lastSelectedItemIndex - 1;
      }
      for (let i = startIndex; i <= endIndex; i++) {
        selectSingleRow(this.optimizationSkuConfigs[i]);
      }
    }
  }

  importRules(file: ReadFile): void {
    this.uiBlockerService.block();
    this.optimizationReportService.importOptimizationRules(this.projectId, this.modelRunId, file).subscribe(
      (data: OptimizationRule) => {
        this.optimizationRule = data;
        this.report.rules = data.rules;
        this.uiBlockerService.unblock();
        this.snackBarService.openSnackBar('Rules uploaded successfully.', 'success');
      }, () => {
        this.snackBarService.openSnackBar('Rules failed to upload.', 'error');
        this.uiBlockerService.unblock();
      });
  }

  deleteOptimizationRule(): void {
    this.uiBlockerService.block();
    this.optimizationReportService.deleteOptimizationRules(
      this.optimizationRule.projectId,
      this.optimizationRule.modelRunId,
      this.optimizationRule.id).subscribe(
      () => {
        this.optimizationRule = null;
        this.report.rules = null;
        this.uiBlockerService.unblock();
      }, () => {
        this.uiBlockerService.unblock();
      });
  }

  startOptimization(): void {
    this.uiBlockerService.block();
    this.report.name = this.report.name.trim();
    this.report.description = this.report.description?.trim();
    this.optimizationReportService.add(this.report).subscribe(
      (optimizationReport: OptimizationReport) => {
        if (optimizationReport.jobId) {
          // redirect to list view
          this.snackBarService.openSnackBar('Optimization run submitted successfully.', 'success');
          this.report = optimizationReport;
          this.closeCreateOptimizationReport();
        }
        this.uiBlockerService.unblock();
      }, () => {
        this.uiBlockerService.unblock();
      });
  }

  async canDeactivate(): Promise<boolean> {
    if (this.report.jobId) {
      return new Promise((resolve) => {
        resolve(true);
      });
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '800px';
    dialogConfig.data = {
      header: 'Exit Optimization Run Setup?',
      body: 'Changes will not be saved. Press \'Exit\' to continue, otherwise press \'Cancel\'.',
      confirmButtonLabel: 'EXIT',
      cancelButtonLabel: 'CANCEL'
    };
    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, dialogConfig);
    return new Promise((resolve) => {
      dialogRef.afterClosed().subscribe(value => {
        const isExiting = value === 'EXIT';
        if (!isExiting) {
          // set the selected tab to the optimization tab if user decides to cancel out of transition
          this.reportService.activeReportSubject.next('optimization');
        }
        resolve(isExiting);
      });
    });
  }

  applyOptimizationReportName(name: string, focusOut: boolean): void {
    if (focusOut && name.trim() === '') {
      name = this.optimizationReportService.getNewReportName(this.reportNameMaxLength);
    }
    this.report.name = name;
    this.validateModelProperty('name');
    this.toggleStartRunButton();
  }

  applyOptimizationReportDescription(description: string): void {
    this.report.description = description;
    this.validateModelProperty('description');
    this.toggleStartRunButton();
  }

}
