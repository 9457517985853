<h2 mat-dialog-title>Export Scenarios</h2>
<app-message-prompt *ngIf="exportWarnMessage" [message]="exportWarnMessage" [type]="'warn'"></app-message-prompt>

<div class="scenario-tree-box">
    <div class="flex tree-header">
        <div class="width-30p">
            <mat-checkbox
                    [checked]="allScenariosSelected()"
                    [indeterminate]="someScenariosSelected()"
                    (change)="toggleAllScenarios($event)">Scenario Name</mat-checkbox>
        </div>
        <div class="width-40p">Description</div>
        <div class="width-30p">Owner</div>
    </div>
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="parent-tree-node">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding class="{{node.hasParent?'in-group-node':'not-in-group-node'}}">
            <div class="w-30">
                <mat-checkbox
                    [matTooltip]="node.name"
                    class="checklist-leaf-node"
                    [checked]="checklistSelection.isSelected(node)"
                    (change)="toggleScenario(node);">
                    <div class="width-20p scenario-name text-overflow-ellipsis" >{{node.name}}</div>
                </mat-checkbox>
            </div>
            <div class="w-30 desc-center-align">
                <div class="width-30p scenario-description text-overflow-ellipsis" [matTooltip]="node.description">{{node.description}}</div>
            </div>
            <div class="w-30">
                <div class="width-20p scenario-owner">{{node.scenarioId | getScenarioOwnerName:isInternalUser}}</div>
            </div>
        </mat-tree-node>

        <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding class="tree-child-node">
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
                <span class="{{treeControl.isExpanded(node) ? 'sif sif-chevron-s' : 'sif sif-chevron-e'}}"></span>
            </button>
            <mat-checkbox
                    [checked]="allScenariosInGroupSelected(node)"
                    [indeterminate]="someScenariosInGroupSelected(node)"
                    (change)="toggleScenarioInGroup(node)">
                <div class="scenario-group-name">{{node.name}}</div>
            </mat-checkbox>
        </mat-tree-node>
    </mat-tree>
</div>

<div class="dialog-actions">
    <div><mat-checkbox *ngIf="isInternalUser" [(ngModel)]="exportingForChartingTool" class="export-for-charting">Export for Charting Tool</mat-checkbox></div>
    <div mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cancel</button>
        <button mat-raised-button color="primary" (click)="exportSelectedScenarios()" [disabled]="disableExportButton ? true : null" class="export-btn">Export</button>
    </div>
</div>
