import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {PriceElasticityService} from '../../../../services/price-elasticity.service';
import {UiBlockerService} from '../../../../services/ui-blocker.service';
import {ModelRunService} from '../../../../services/model-run.service';
import {ModelRun} from '../../../../models/model-run.model';
import {PriceElasticityReport} from '../../../../models/price-elasticity-report.model';
import {GenericConfirmationModalComponent} from '../../../../components/generic-confirmation-modal/generic-confirmation-modal.component';
import {SnackBarService} from '@app/components/snack-bar/snack-bar.service';
import {WebMessageService} from '@app/services/web-message.service';
import {AppConstantsService} from '@app/services/app-constants.service';
import {SizeElasticityService} from "@app/services/size-elasticity.service";
import {SizeElasticityReport} from "@app/models/size-elasticity-report.model";

const SIZE_ELASTICITY_REPORT_MSG_GROUP = 'size-elasticity-report';

@Component({
  selector: 'app-list-size-elasticity-report',
  templateUrl: './list-size-elasticity-report.component.html',
  styleUrls: ['./list-size-elasticity-report.component.scss']
})
export class ListSizeElasticityReportComponent implements OnInit, OnDestroy {

  modelRun: ModelRun;

  reports: Array<SizeElasticityReport>;

  subscriptions: Subscription;

  get projectId(): number {
    return this.modelRun.projectId;
  }

  get modelRunId(): string {
    return this.modelRun.id;
  }

  constructor(private route: ActivatedRoute,
              private router: Router,
              private modelRunService: ModelRunService,
              private uiBlockerService: UiBlockerService,
              private sizeElasticityService: SizeElasticityService,
              private dialog: MatDialog,
              private snackBarService: SnackBarService,
              private ngZone: NgZone,
              private webMessageService: WebMessageService) {
  }

  ngOnInit(): void {
    this.subscriptions = new Subscription();
    /**
     * Angular nuances, when parent route(reports) param changes we need to watch it and reset the data accordingly.
     * I am not sure why it just doesn't re-render the route like in Ember.
     */
    this.subscriptions.add(this.route.parent.parent.params.subscribe(() => {
      this.uiBlockerService.block();
      this.reports = [];
      this.modelRun = this.modelRunService.activeModelRun;
      this.sizeElasticityService.fetchAll(this.projectId, this.modelRunId).subscribe((reports: SizeElasticityReport[]) => {
        this.reports = reports;
        this.uiBlockerService.unblock();
        if (reports.length > 0) {
          this.webMessageService.consumeMessage(`${SIZE_ELASTICITY_REPORT_MSG_GROUP}-${this.modelRunId}`).subscribe((eventData) => {
            const event = JSON.parse(eventData);
            const updatedReport = this.sizeElasticityService.refreshCachedModel(event.data);
            if (updatedReport) {
              this.reports.splice(this.reports.findIndex(it => it.id === updatedReport.id), 1, updatedReport);
            }
          });
        }
      });
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.webMessageService.close(`${SIZE_ELASTICITY_REPORT_MSG_GROUP}-${this.modelRunId}`);
  }

  createSizeElasticityRun(): void {
    this.router.navigate(['create'], {relativeTo: this.route.parent});
  }

  /**
   * Downloads optimization report file.
   * @see https://stackoverflow.com/questions/51960172/set-file-name-while-downloading-via-blob-in-angular-5
   */
  downloadReport(report: SizeElasticityReport): void {
    this.uiBlockerService.block();
    this.sizeElasticityService.generateReport(report).subscribe((response) => {
      const blob = new Blob([response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.download = `${report.name}.xlsx`;
      anchor.href = url;
      anchor.click();
      this.uiBlockerService.unblock();
    }, error => {
      this.uiBlockerService.unblock();
      this.snackBarService.openErrorSnackBar('Failed downloading report.');
    });
  }

  deleteReport(report: SizeElasticityReport): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '800px';
    dialogConfig.data = {
      header: 'Delete?',
      body: 'Are you sure you want to delete the report?',
      cancelButtonLabel: 'CANCEL',
      confirmButtonLabel: 'DELETE'
    };

    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(value => {
      if (value === 'DELETE') {
        this.uiBlockerService.block();
        this.sizeElasticityService.delete(report).subscribe(() => {
          this.sizeElasticityService.getAll(this.projectId, this.modelRunId).subscribe((reports: SizeElasticityReport[]) => {
            this.reports = reports;
            this.uiBlockerService.unblock();
          });
        }, error => {
          this.uiBlockerService.unblock();
        });
      }
    });
  }

  duplicate(report: SizeElasticityReport) {
    this.router.navigate(['create'], {
      relativeTo: this.route.parent,
      queryParams: {source: report.id}
    });
  }
}
