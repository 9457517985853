import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {OptimizationReport} from './../models/optimization-report.model';
import {EnvironmentService} from './../services/environment.service';
import {HttpClient} from '@angular/common/http';
import {ReadFile} from 'ngx-file-helpers';
import {OptimizationRule} from '@app/models/optimization-rule.model';
import {UtilService} from '@app/services/util.service';
import {ModelCacheUtil} from '@app/utils/model-cache-util';

@Injectable({
  providedIn: 'root'
})
export class OptimizationReportService {

  /**
   * Cached list of optimization reports
   */
  private cache: ModelCacheUtil<OptimizationReport>;

  constructor(private http: HttpClient, private environmentService: EnvironmentService, private utilService: UtilService) {
    this.cache = new ModelCacheUtil<OptimizationReport>();
  }

  get optimizationReports(): Array<OptimizationReport> {
    return this.cache.cachedModels;
  }

  getNewReportName(maxLength: number): string {
    const cachedReports = this.cache.cachedModels;
    const prependName = `Untitled Optimization`;
    return this.utilService.generateReportName(prependName, maxLength, cachedReports);
  }

  getDuplicateReportName(prependName: string, maxLength: number): string {
    const cachedReports = this.cache.cachedModels;
    return this.utilService.generateDuplicateReportName(prependName, maxLength, cachedReports);
  }

  fetchAll(projectId: number, modelRunId: string): Observable<Array<OptimizationReport>> {
    const env = this.environmentService.environment.authProxy;
    const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${projectId}/runs/${modelRunId}/optimizationReports`;
    return this.http.get<Array<OptimizationReport>>(url).pipe(map((reports: OptimizationReport[]) => {
        this.cache.appendAll(reports);
        return reports;
      }
    ));
  }

  getAll(projectId: number, modelRunId: string): Array<OptimizationReport> {
    return this.cache.filter((optimizationReport: OptimizationReport) => {
      return optimizationReport.projectId === projectId && optimizationReport.modelRunId === modelRunId;
    });
  }

  add(optimizationReport: OptimizationReport): Observable<OptimizationReport> {
    const env = this.environmentService.environment.authProxy;
    const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${optimizationReport.projectId}/runs/${optimizationReport.modelRunId}/optimizationReports`;
    return this.http.post<OptimizationReport>(url, {optimizationReport}).pipe(map((report: OptimizationReport) => {
        this.cache.append(report);
        return report;
      }
    ));
  }

  delete(optimizationReport: OptimizationReport): Observable<OptimizationReport> {
    const env = this.environmentService.environment.authProxy;
    const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${optimizationReport.projectId}/runs/${optimizationReport.modelRunId}/optimizationReports/${optimizationReport.id}`;
    return this.http.delete<OptimizationReport>(url).pipe(map((response) => {
        this.cache.remove(optimizationReport);
        return response;
      }
    ));
  }

  importOptimizationRules(projectId: number, modelRunId: string, file: ReadFile): Observable<OptimizationRule> {
    const env = this.environmentService.environment.authProxy;
    const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${projectId}/runs/${modelRunId}/optimizationRules`;
    const formData = new FormData();
    formData.append('file', file.underlyingFile);
    return this.http.post<OptimizationRule>(url, formData);
  }

  deleteOptimizationRules(projectId: number, modelRunId: string, id: string): Observable<OptimizationRule> {
    if (id) {
      const env = this.environmentService.environment.authProxy;
      const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${projectId}/runs/${modelRunId}/optimizationRules/${id}`;
      return this.http.delete<OptimizationRule>(url);
    } else {
      return of(null);
    }
  }

  /**
   * Method is use to download file.
   * @param data - Array Buffer data
   * @param type - type of the document.
   */
  generateReport(report: OptimizationReport): Observable<ArrayBuffer> {
    const env = this.environmentService.environment.authProxy;
    const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${report.projectId}/runs/${report.modelRunId}/optimizationReports/${report.id}/download`;
    return this.http.get(`${url}`, {
        responseType: 'arraybuffer'
      }
    );
  }

  /**
   * Returns single price elasticity report associated with given project and run id.
   */
  getById(id: string, params: { projectId: number; modelRunId: string }): Observable<OptimizationReport> {
    const report = this.cache.find((it: OptimizationReport) => {
      return it.id === id;
    });
    if (report) {
      return of(report);
    } else {
      const env = this.environmentService.environment.authProxy;
      const contextPath = `${env.url}/${env.lpoSimulatorContextPath}`;
      const url = `${contextPath}/projects/${params.projectId}/runs/${params.modelRunId}/optimizationReports/${id}`;
      return this.http.get<OptimizationReport>(url).pipe(map((singleReport: OptimizationReport) => {
        this.cache.append(singleReport);
        return singleReport;
      }));
    }
  }

  refreshCachedModel(updatedData: OptimizationReport): OptimizationReport {
    if (updatedData) {
      const updatedModel = <OptimizationReport>updatedData;
      return this.cache.refresh(updatedModel);
    }
    return null;
  }
}
