import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, OnDestroy} from '@angular/core';
import {HotTableRegisterer} from '@handsontable/angular';
import Handsontable from 'handsontable';
import {roundTo, valueHasPercentageAtTheEnd, numericRangeValidator} from '../../utils/sku-config.validator';
import {SkuConfigTableCellRenderers} from '../../utils/sku-config-table-cell-renderers';
import {Subject, Subscription} from 'rxjs';
import {ScenarioService} from '@app/services/scenario.service';
import {EnvironmentService} from '@app/services/environment.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ColumnChooserComponent} from '../../components/column-chooser/column-chooser.component';
import {SkuGroup} from '../../models/sku-group.model';
import {UserConfigurations} from '../../models/user-configurations.model';
import {UserConfigurationsService} from '@app/services/user-configurations.service';
import {AppConstantsService} from '@app/services/app-constants.service';
import CellChange = Handsontable.CellChange;
import {AuthProxyService} from '@app/services/auth-proxy.service';
import {ActivatedRoute} from '@angular/router';
import {UiBlockerService} from '@app/services/ui-blocker.service';
import {MetaDataService} from '@app/services/meta-data.service';
import {ProjectService} from '@app/services/project.service';
import {Scenario} from '@app/models/scenario.model';

@Component({
  selector: 'app-sku-config-table',
  templateUrl: './sku-config-table.component.html',
  styleUrls: ['./sku-config-table.component.scss'],
})
export class SkuConfigTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() hotTableData: any;
  @Input() tableSettings: any;
  @Input() scenarioId: string;
  @Input() skuGroups: any;
  @Input() hasUnSelectedNonSampleFilter: boolean;
  @Input() dropdownNotifier: Subject<any>;
  @Input() outPutFieldsTotal: any;
  @Input() cellRenderers: SkuConfigTableCellRenderers;
  @Input() metaData: any;
  /**
   * Clear all sku config result fields when clearOutputs is set to true
   * */
  @Input() clearOutputs: boolean;
  /**
   * If enabled, then the table should be in edit mode.
   * */
  @Input() editMode: boolean;
  /**
   * Subject when a next is called will trigger handsontable to re-render.
   * */
  @Input() reloadTableSubject: Subject<any>;
  @Output() profitInputClickEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() errorMessageEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() tableChangesEvent: EventEmitter<any> = new EventEmitter<any>();

  subscriptions: Subscription;
  hotTableInstance: any;
  hotTableId = 'skuConfigTable';
  hotTableSettings: Handsontable.GridSettings = {};
  invalidCells = new Map(); // holds invalid hot table cells
  errorMessages: string[] = [];
  activeGroup: any;
  activeBrandsList: string[] = [];
  groupsHierarchy: Array<any> = [];
  onChangeScenarioSubscription: Subscription;
  filterState: any = [];
  allFilteredRows: any = [];
  resetOutputs: boolean;
  dialogRef: any;
  skuConfigColumnChooser: UserConfigurations;
  tableSettingsWithColumnChooser: any = {
    visibleColumns: [],
    visibleColumnHeaders: [],
    groupHeaders: [],
    allowFiltersForHeaders: [],
    groupingsStartAtIndex: 3,
    inputsStartAtIndex: 0,
    outputsStartAtIndex: 0
  };
  brandsSelected: any;
  isAllSkusSelectedState: number;
  selectedSkuCount: number;
  currentCollapsedColumns: any;
  profitSettingsTooltip: string;

  constructor(private hotTableRegisterer: HotTableRegisterer,
              private scenarioService: ScenarioService,
              private environmentService: EnvironmentService,
              private dialog: MatDialog,
              private userConfigurationsService: UserConfigurationsService,
              private appConstantsService: AppConstantsService,
              private metaDataService: MetaDataService,
              private authProxyService: AuthProxyService,
              private route: ActivatedRoute,
              private uiBlockerService: UiBlockerService,
              private projectService: ProjectService) {
  }

  ngOnInit(): void {
    this.brandsSelected = [];
    this.activeGroup = '';
    this.subscriptions = new Subscription();
    this.setSelectAllCheckBoxState();
    this.currentCollapsedColumns = [];
    this.reloadTableSubject.subscribe(() => {
      this.loadHotTable();
    });
    this.onChangeScenarioSubscription = this.dropdownNotifier.subscribe((data) => {
      if (data.isFromSimulateSave || data.isFromfluoutClose) {
        this.tableSettings.columns = data.tableSettings.columns;
        this.tableSettings.columnHeaders = data.tableSettings.columnHeaders;
        this.outPutFieldsTotal = data.outPutFieldsTotal;
        this.hotTableData = data.hotData;
        this.skuGroups = data.skuGroups;
        if (data.isFromfluoutClose) {
          this.activeGroup = '';
          this.brandsSelected = [];
        }
        this.setSelectAllCheckBoxState();
        this.loadHotTable();
      }
    });
    this.subscriptions.add(this.route.queryParams.subscribe((p) => {
      if (p.profit) {
        this.openProfitDialog();
        this.uiBlockerService.unblockAll();
      }
    }));
  }

  setSelectAllCheckBoxState(): void {
    const skuConfigRows = (this.hotTableData || []).filter(h => h.skuId > 0);
    const selectedSkuConfigs = skuConfigRows.filter(h => h.isSelected);
    const totalSkuCount = skuConfigRows.length;
    const selectedSkuCount = selectedSkuConfigs.length;
    const unSelectedSkuCount = totalSkuCount - selectedSkuCount;

    this.selectedSkuCount = selectedSkuCount;
    if (selectedSkuCount === totalSkuCount) {
      this.isAllSkusSelectedState = this.appConstantsService.ALL_SKUS_SELECTED;
    } else if (unSelectedSkuCount === totalSkuCount) {
      this.isAllSkusSelectedState = this.appConstantsService.NO_SKUS_SELECTED;
    } else {
      this.isAllSkusSelectedState = this.appConstantsService.PARTIAL_SKUS_SELECTED;
    }
    this.disableSimulateAndSaveOnSelectionChange();
  }

  ngAfterViewInit(): void {
    this.loadHotTable();
    this.inputChangeSubscription();
  }

  ngOnDestroy(): void {
    this.onChangeScenarioSubscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  inputChangeSubscription(): void {
    this.subscriptions.add(this.scenarioService.skuConfigInputsResetSubject.subscribe({
      next: () => {
        this.clearOutputFields();
      }
    }));
  }

  clearOutputData(): void {
    const outputFields = ['psShare', 'prefShare',
      'unit', 'unitsShare', 'unitsPromoShare',
      'volume', 'volumeShare',
      'equivalizedVolume', 'equivalizedVolumeShare',
      'revenue', 'revenueShare',
      'profit'];
    this.hotTableData.forEach((skuConfig: any) => {
      outputFields.forEach((field: string) => {
        skuConfig[field] = skuConfig.isBrand || skuConfig.isGroupLabel ? '--' : '';
      });
    });
    Object.keys(this.outPutFieldsTotal).forEach(key => {
      this.outPutFieldsTotal[(key)] = 0;
    });
  }

  clearOutputFields(): void {
    this.resetOutputs = true;
    let hasOutputs = false;
    Object.keys(this.outPutFieldsTotal).forEach(key => {
      if (this.outPutFieldsTotal[key] > 0) {
        hasOutputs = true;
      }
    });
    if (hasOutputs && this.resetOutputs && this.hotTableInstance && !this.hotTableInstance.destroyed) {
      this.clearOutputData();
      this.loadHotTable();
    }
  }

  loadHotTable(): void {
    this.hotTableInstance = this.hotTableRegisterer.getInstance(this.hotTableId);
    this.groupsHierarchy = [];
    this.activeBrandsList = [];
    this.tableSettingsWithColumnChooser.allowFiltersForHeaders = [];
    this.tableSettingsWithColumnChooser.visibleColumns = [];
    this.tableSettingsWithColumnChooser.visibleColumnHeaders = [];
    this.tableSettingsWithColumnChooser.groupHeaders = [];
    this.tableSettingsWithColumnChooser.groupingsStartAtIndex = this.tableSettings.groupColumnStartIndex;
    this.tableSettingsWithColumnChooser.inputsStartAtIndex = this.tableSettings.inputsColumnStartIndex;
    this.tableSettingsWithColumnChooser.outputsStartAtIndex = this.tableSettings.outPutColumnStartIndex;
    this.applyUserConfigurationsForSkuConfigTable();
    const hasActiveGroupIsHidden = this.skuConfigColumnChooser.configurations['hideGroups'].find(h => {
      return this.activeGroup && h.displayName === this.activeGroup.displayName && !h.showInRow;
    });
    if (hasActiveGroupIsHidden) {
      this.activeGroup = '';
      this.brandsSelected = [];
    }
    if (this.hotTableInstance) {
      this.clearFilterState();
      if (this.skuGroups && this.skuGroups.length) {
        let index = 0;
        this.skuGroups.forEach((skuGroup) => {
          const groupChooser = this.skuConfigColumnChooser.configurations['hideGroups'].find((c) => {
            return c.name === skuGroup.name;
          });
          if (!groupChooser || groupChooser.showInRow) {
            this.activeGroup = !this.activeGroup && index === 0 ? skuGroup : this.activeGroup;
            index++;
            this.groupsHierarchy[(skuGroup.skuGroupId)] = [];
            this.groupsHierarchy[skuGroup.skuGroupId] = this.sortOnDisplayOrder(skuGroup).map(sg => {
              return sg.displayName;
            });
          }
        });
        if (this.activeGroup) {
          this.activeBrandsList = this.activeGroup.itemGroupings.map(sg => {
            return sg.displayName;
          });
          this.hotTableData.map((hotObj) => {
            hotObj['isSelected'] = hotObj['isSelected'] ? 1 : 0;
            return hotObj;
          });
          this.removeFilterOnReportingNameColumn();
          this.constructGroupsHotData();
        } else {
          this.setCheckBoxData();
        }
      } else {
        this.setCheckBoxData();
      }
      this.hotTableInstance.loadData(this.hotTableData);
      this.generateTable(this.hotTableData, this.hotTableInstance);
      this.insertPsShareNotaValueAtLastRow();
      if (this.skuGroups.length && this.activeGroup) {
        this.showOnlyActiveGroupBrands();
      }
      if (this.clearOutputs) {
        this.clearOutputData();
      }
      this.hotTableInstance.render();
    }
    this.setExpandCollapseStateOfColumnHeaders();

  }

  /**
   * set isSelected value true as 1 and false as 0
   * used when no active group or no  group exist
   */
  setCheckBoxData(): void {
    this.hotTableData.map((hotObj) => {
      hotObj['hasActiveGroup'] = false;
      hotObj['isSelected'] = hotObj['isSelected'] ? 1 : 0;
      return hotObj;
    });
    this.activeBrandsList = [];
    this.hotTableData = this.scenarioService.getSkuConfigsInDisplayOrder(this.hotTableData);
  }

  clearFilterState(): void {
    const conditionCollection = this.hotTableInstance && this.hotTableInstance.getPlugin('Filters').conditionCollection;
    if (conditionCollection) {
      this.hotTableInstance.getPlugin('Filters').clearConditions();
    }
  }

  setExpandCollapseStateOfColumnHeaders(): void {
    const collapsedHeaderIndexes = [];
    if (this.currentCollapsedColumns.length) {
      if (this.currentCollapsedColumns.indexOf(this.tableSettingsWithColumnChooser.groupingsStartAtIndex + 1) !== -1) {
        collapsedHeaderIndexes.push(this.tableSettingsWithColumnChooser.groupingsStartAtIndex);
      }
      if (this.currentCollapsedColumns.indexOf(this.tableSettingsWithColumnChooser.inputsStartAtIndex + 1) !== -1) {
        collapsedHeaderIndexes.push(this.tableSettingsWithColumnChooser.inputsStartAtIndex);
      }
      if (this.currentCollapsedColumns.indexOf(this.tableSettingsWithColumnChooser.outputsStartAtIndex + 1) !== -1) {
        collapsedHeaderIndexes.push(this.tableSettingsWithColumnChooser.outputsStartAtIndex);
      }
      if (collapsedHeaderIndexes.length) {
        const colPlugin = this.hotTableInstance.getPlugin('collapsibleColumns');
        collapsedHeaderIndexes.forEach(index => {
          colPlugin.toggleCollapsibleSection([{col: index, row: -2}], 'collapse');
        });
      }
    }
  }

  /**
   *   this method handles default state of the skuGroups and its brands
   */
  showOnlyActiveGroupBrands(): void {
    const rowCount = this.hotTableInstance.countRows();
    const rowsToHide = [];
    const plugin = this.hotTableInstance.getPlugin('hiddenRows');
    for (let i = 0; i < rowCount; i++) {
      const rowData = this.hotTableInstance.getSourceDataAtRow(i);
      const cellMeta = this.hotTableInstance.getCellMeta(i, 2);
      let paddingClassName = '';
      if (rowData.isGroupLabel || rowData.isBrand) {
        paddingClassName = rowData.isGroupLabel ? 'padding-left-1' : 'padding-left-3-point-4';
        cellMeta.className = rowData.isSelected ? `ellipsis nota-cell htLeft ${paddingClassName} sif-13 sif sif-chevron-s` : `ellipsis  nota-cell htLeft ${paddingClassName} sif-13 sif sif-chevron-e`;
        for (let j = 0; j < this.tableSettings.columns.length; j++) {
          if (j !== 2) {
            const idColProps = this.hotTableInstance.getCellMeta(i, j);
            const cellValue = this.hotTableInstance.getDataAtCell(i, j);
            idColProps.className = cellValue && j !== 0 && j !== 1 ? 'htRight nota-cell' : 'hatch-cell';
            idColProps.type = 'text';
            idColProps.readOnly = true;
            idColProps.disableVisualSelection = idColProps.className === 'hatch-cell';
            this.hotTableInstance.setCellMeta(i, j, idColProps);
          }
        }
      } else {
        cellMeta.className = 'ellipsis padding-left-4-point-6';
      }
      this.hotTableInstance.setCellMeta(i, 2, cellMeta);
      if (!rowData.isGroupLabel && (rowData.groupLabel !== this.activeGroup.displayName && rowData.reportingName !== 'NOTA')) {
        const brandRow = this.hotTableData.find((h) => {
          return h.brandName === rowData.brandName && h.groupId === rowData.groupId && h.isBrand && h.isSelected;
        });
        if (rowData.groupLabel !== this.activeGroup.displayName && !brandRow) {
          if (rowsToHide.indexOf(i) === -1) {
            rowsToHide.push(i);
          }
        }
      }
    }
    plugin.hideRows(rowsToHide);
  }

  /**
   *   used to show the rows belongs to selected brands
   */
  showOnlyActiveGroupItems(isActiveBrand = false): Array<number> {
    const rowsToShow = [];
    this.allFilteredRows.forEach(i => {
      let rowOpen = 0;
      const rData = this.hotTableInstance.getSourceDataAtRow(i);
      rowOpen = this.hotTableInstance.getSourceData().filter((r) => {
        return rData.brandName === r.brandName && r.groupId === rData.groupId && (isActiveBrand || r.isSelected) && r.isBrand;
      }).length;
      if (rowOpen) {
        rowsToShow.push(i);
      }
    });
    return rowsToShow;
  }

  /**
   *  Used to  insert NOTA row at the bottom of the sku-config table.
   */
  insertPsShareNotaValueAtLastRow(): void {
    const psShareColumnIndex = this.tableSettingsWithColumnChooser.visibleColumns.findIndex((column) => column.name === 'psShare');
    if (psShareColumnIndex > 0 && this.metaData.outputConfigurations.psShare &&
      this.metaData.outputConfigurations.psShare.showInSimulator && this.outPutFieldsTotal.totalPsShare > 0) {
      this.hotTableInstance.alter('insert_row', this.hotTableInstance.countRows());
      const rowCount = this.hotTableInstance.countRows();
      const nameColumnIndex = this.tableSettingsWithColumnChooser.visibleColumns.findIndex((column) => column.name === 'reportingName');
      const promoPriceColumnIndex = this.tableSettingsWithColumnChooser.visibleColumns.findIndex((column) => column.name === 'promoPrice');
      for (let i = 0; i < this.tableSettingsWithColumnChooser.visibleColumns.length; i++) {
        const cellMeta = this.hotTableInstance.getCellMeta(rowCount - 1, i);
        cellMeta.readOnly = true;
        if (i === promoPriceColumnIndex) {
          cellMeta.type = 'text';
        }
        if (i === psShareColumnIndex) {
          cellMeta.className = 'htRight nota-cell';
          const data = 1 - this.outPutFieldsTotal.totalPsShare;
          this.hotTableInstance.setDataAtCell(rowCount - 1, i, this.cellRenderers.getFormattedOutputShareValue(`${data}`));
        } else if (i === nameColumnIndex) {
          this.hotTableInstance.setDataAtCell(rowCount - 1, i, 'NOTA');
          cellMeta.className = 'nota-cell';
        } else {
          cellMeta.className = 'hatch-cell';
          cellMeta.disableVisualSelection = cellMeta.readOnly;
          this.hotTableInstance.setDataAtCell(rowCount - 1, i, '');
        }
      }
    }
  }

  sortOnDisplayOrder(skuGroup): Array<any> {
    const itemGroupingsFinal = [];
    skuGroup.itemGroupings.sort((itemGrouping1, itemGrouping2) => {
      return itemGrouping1.displayOrder - itemGrouping2.displayOrder;
    });
    skuGroup.itemGroupings.forEach(iGrouping => {
      if (iGrouping.name === 'All Other') {
        const skuWithAllOther = this.hotTableData.find(sku => {
          return sku.groups && sku.groups.find(sg => {
            return sg.itemGroupingId === iGrouping.itemGroupingId
              && skuGroup.skuGroupId === sg.skuGroupId;
          });
        });
        if (skuWithAllOther) {
          itemGroupingsFinal.push(iGrouping);
        }
      } else {
        itemGroupingsFinal.push(iGrouping);
      }
    });
    return itemGroupingsFinal;
  }

  /**
   *   Constructs the skuConfig data in Group(groupSet)->Brand(itemGrouping)->item(sku) hierarchy
   *   to recognise the group/brand row isGroupLabel/isBrand filed is used.
   *   each skuItem has given its group name and brand name respectively.
   *   outputs for each brand row is calculated and assign to brand row
   */
  constructGroupsHotData(): void {
    this.hotTableData = this.scenarioService.getSkuConfigsInDisplayOrder(this.hotTableData);
    const groupsHotData = [];
    this.allFilteredRows = [];
    this.filterState = [];
    if (this.activeGroup) {
      this.skuGroups.forEach((skuGroup) => {
        const groupChooser = this.skuConfigColumnChooser.configurations['hideGroups'].find((c) => {
          return c.name === skuGroup.name;
        });
        if (!groupChooser || groupChooser.showInRow) {
          const selectGroup = this.activeGroup.skuGroupId === skuGroup.skuGroupId;
          groupsHotData.push({
            reportingName: skuGroup.displayName,
            isGroupLabel: true,
            groupLabel: skuGroup.displayName,
            isSelected: selectGroup,
            groupId: skuGroup.skuGroupId,
            promoPrice: null,
            psShare: this.outPutFieldsTotal.totalPsShare && this.outPutFieldsTotal.totalPsShare > 0 && selectGroup ?
              this.cellRenderers.getFormattedOutputShareValue(`${this.outPutFieldsTotal.totalPsShare}`) : null
          });
          const brandsInGroup = this.groupsHierarchy[skuGroup.skuGroupId];
          brandsInGroup.forEach((brand) => {
            let brandRow =
              {
                brandName: brand,
                reportingName: brand,
                isBrand: true,
                groupLabel: skuGroup.displayName,
                isGroupLabel: false,
                isSelected: this.brandsSelected.indexOf(brand) !== -1 || selectGroup ? 1 : 0,
                groupId: skuGroup.skuGroupId
              };
            brandRow = this.setOutputsForBrandRow(brandRow, this.hotTableData, this.activeGroup, brand);
            groupsHotData.push(brandRow);
            if (this.activeGroup.skuGroupId === skuGroup.skuGroupId) {
              this.hotTableData.forEach((hotObj) => {
                if (hotObj[skuGroup.displayName] === brand) {
                  hotObj['brandName'] = brand,
                    hotObj['groupName'] = skuGroup.displayName;
                  hotObj['groupId'] = skuGroup.skuGroupId;
                  hotObj['hasActiveGroup'] = true;
                  groupsHotData.push(hotObj);
                }
              });
            }
          });
        }
      });
      this.hotTableData = groupsHotData;
    }
  }

  /**
   * calculates the outputs for brand row using totals provided
   * @param brandObj is to push brandRow
   * @param hotTableData is hotData to use for calculation
   * @param activeGroup is activeGroup which is operating now
   * @param brand is a brandName
   */
  setOutputsForBrandRow(brandObj, hotTableData, activeGroup, brand): any {
    const outputFields = ['psShare', 'unit', 'unitsShare', 'unitsPromoShare', 'volume', 'volumeShare', 'equivalizedVolume',
      'equivalizedVolumeShare', 'revenue', 'revenueShare', 'profit'];
    hotTableData.forEach((sku) => {
      const groupObj = sku.groups ? sku.groups.find((group) => {
        const itemGrouping = this.activeGroup.itemGroupings.find(sg => {
          return sg.displayName === brand;
        });
        return itemGrouping && group.skuGroupId === this.activeGroup.skuGroupId && (group.itemGroupingId === itemGrouping.itemGroupingId);
      }) : null;
      outputFields.forEach((dataKey) => {
        if (dataKey && groupObj) {
          if (!brandObj[(dataKey)]) {
            brandObj[(dataKey)] = 0;
          }
          if(dataKey === 'unitsPromoShare'){
            let sumProduct = sku[(dataKey)] && sku['unitsShare'] ? parseFloat(sku[(dataKey)]) * parseFloat(sku['unitsShare']) : 0 ;
            brandObj[(dataKey)] =  brandObj[(dataKey)] + sumProduct ;
          }else{
            brandObj[(dataKey)] = brandObj[(dataKey)] + (sku[(dataKey)] ? parseFloat(sku[(dataKey)]) : 0);
          }

        }
      });
    });
    brandObj['psShare'] = brandObj['psShare'] ? this.cellRenderers.getFormattedOutputShareValue(brandObj['psShare']) : '--';
    let unitsShare = brandObj['unitsShare'] ;
    brandObj['unitsShare'] = brandObj['unitsShare'] ? this.cellRenderers.getFormattedOutputShareValue(brandObj['unitsShare']) : '--';
    brandObj['unit'] = brandObj['unit'] ? this.cellRenderers.getFormattedOutputValue(brandObj['unit']) : '--';
    let unitsOnPromoShare = unitsShare && brandObj['unitsPromoShare'] ? (brandObj['unitsPromoShare']/unitsShare) : 0 ;
    brandObj['unitsPromoShare'] = unitsOnPromoShare ? this.cellRenderers.getFormattedOutputShareValue(unitsOnPromoShare.toString()) : '--';
    brandObj['revenueShare'] = brandObj['revenueShare'] ? this.cellRenderers.getFormattedOutputShareValue(brandObj['revenueShare']) : '--';
    brandObj['revenue'] = brandObj['revenue'] ? this.cellRenderers.getFormattedOutputValueForRevenue(brandObj['revenue']) : '--';
    brandObj['volumeShare'] = brandObj['volumeShare'] ? this.cellRenderers.getFormattedOutputShareValue(brandObj['volumeShare']) : '--';
    brandObj['volume'] = brandObj['volume'] ? this.cellRenderers.getFormattedOutputValue(brandObj['volume']) : '--';
    brandObj['profit'] = brandObj['profit'] ? this.cellRenderers.getFormattedOutputValueForRevenue(brandObj['profit']) : '--';
    brandObj['equivalizedVolumeShare'] = brandObj['equivalizedVolumeShare'] ? this.cellRenderers.getFormattedOutputShareValue(brandObj['equivalizedVolumeShare']) : '--';
    brandObj['equivalizedVolume'] = brandObj['equivalizedVolume'] ? this.cellRenderers.getFormattedOutputValue(brandObj['equivalizedVolume']) : '--';
    return brandObj;
  }

  getRowsByAllBrands(rowCount, isToHide = false): Array<number> {
    const resultRowIds = [];
    for (let i = 0; i < rowCount; i++) {
      const rowData = this.hotTableInstance.getSourceDataAtRow(i);
      if (this.activeBrandsList.indexOf(rowData.brandName) !== -1 &&
        rowData.isBrand && rowData.groupId === this.activeGroup.skuGroupId) {
        if (isToHide && rowData.isSelected) {
          this.hotTableInstance.setDataAtCell(i, 1, false);
          const cellMeta = this.hotTableInstance.getCellMeta(i, 2);
          cellMeta.className = 'ellipsis nota-cell htLeft padding-left-3 sif-13 sif sif-chevron-e';
          this.hotTableInstance.setCellMeta(i, 2, cellMeta);
        }
        resultRowIds.push(i);
      }
    }
    return resultRowIds;
  }

  getRowsByBrand(groupId, brand, rowCount): Array<number> {
    const resultRowIds = [];
    for (let i = 0; i < rowCount; i++) {
      const rowData = this.hotTableInstance.getSourceDataAtRow(i);
      if (rowData.groupId === groupId && rowData.brandName === brand && !rowData.isBrand
        && resultRowIds.indexOf(i) === -1) {
        resultRowIds.push(i);
      }
    }
    return resultRowIds;
  }

  getRowsByFilter(columnIndex, args): Array<number> {
    const rowCount = this.hotTableInstance.countRows();
    if (this.filterState[(columnIndex)] && this.filterState[(columnIndex)].length > 0) {
      this.allFilteredRows = this.allFilteredRows.filter(x => this.filterState[(columnIndex)].indexOf(x) < 0);
    }
    this.filterState[(columnIndex)] = [];
    const resultRowIds = [];
    for (let i = 0; i < rowCount; i++) {
      const rowData = this.hotTableInstance.getSourceDataAtRow(i);
      if (!(rowData.isGroupLabel || rowData.isBrand) &&
        args.indexOf(rowData[(this.tableSettingsWithColumnChooser.visibleColumns[columnIndex].data)]) === -1) {
        resultRowIds.push(i);
      }
    }
    this.filterState[columnIndex].push(resultRowIds);
    resultRowIds.map(r => {
      if (this.allFilteredRows.indexOf(r) === -1) {
        this.allFilteredRows.push(r);
      }
    });
    return resultRowIds;
  }

  /**
   * on each click on group/brand from reportingName column it does show and hise of corresponding rows
   * @description rowNumber which is clicked
   * @param row contains row number
   * @description column number which is clicked on
   * @param col contains column number
   */
  expandCollapseRows(row, col): void {
    const rowData = this.hotTableInstance.getSourceDataAtRow(row);
    const plugin = this.hotTableInstance.getPlugin('hiddenRows');
    const rowCount = this.hotTableInstance.countRows();
    if ((rowData.isGroupLabel || rowData.isBrand) && (this.activeGroup.skuGroupId !== rowData.groupId || !rowData.isSelected)) {
      this.brandsSelected = [];
      this.activeGroup = this.skuGroups.find(sGroup => sGroup.skuGroupId === rowData.groupId);
      this.constructGroupsHotData();
      this.hotTableInstance.loadData(this.hotTableData);
      this.insertPsShareNotaValueAtLastRow();
      this.activeBrandsList = this.groupsHierarchy[rowData.groupId];
      this.showOnlyActiveGroupBrands();
      this.hotTableInstance.render();
      this.clearFilterState();
      this.setExpandCollapseStateOfColumnHeaders();
      return;
    }
    if (rowData.isGroupLabel) {
      this.activeBrandsList = this.groupsHierarchy[this.activeGroup.skuGroupId];
      this.hotTableInstance.setDataAtCell(row, col - 1, !rowData.isSelected);
      let finalRowsToHide = this.getRowsByAllBrands(rowCount, true);
      if (rowData.isSelected) {
        this.activeBrandsList.forEach((brand) => {
          finalRowsToHide = finalRowsToHide.concat(this.getRowsByBrand(rowData.groupId, brand, rowCount));
        });
        plugin.hideRows(finalRowsToHide);
      } else {
        let finalRowsToShow = this.getRowsByAllBrands(rowCount, true);
        finalRowsToShow = finalRowsToShow.filter(rowNumber => this.allFilteredRows.indexOf(rowNumber) < 0);
        plugin.showRows(finalRowsToShow);
      }
      this.hotTableInstance.render();
    } else if (rowData.isBrand) {
      this.hotTableInstance.setDataAtCell(row, col - 1, !rowData.isSelected);
      let rowsByBrand = this.getRowsByBrand(rowData.groupId, rowData.brandName, rowCount);
      if (rowData.isSelected) {
        this.brandsSelected.splice(this.brandsSelected.indexOf(rowData.brandName), 1);
        plugin.hideRows(rowsByBrand);
      } else {
        if (this.brandsSelected.indexOf(rowData.brandName) < 0) {
          this.brandsSelected.push(rowData.brandName);
        }
        rowsByBrand = rowsByBrand.filter(rowNumber => this.allFilteredRows.indexOf(rowNumber) < 0);
        plugin.showRows(rowsByBrand);
      }
      this.hotTableInstance.render();
    }
  }

  filterAllRowsToHide(rowsToShow: Array<number>): void {
    this.allFilteredRows = this.allFilteredRows.filter(rowNumber => rowsToShow.indexOf(rowNumber) < 0);
  }

  get handsontableLicenseKey(): string {
    const handsontableConfig = this.environmentService.environment.handsontable;
    const handsontableLicenseKey = handsontableConfig && handsontableConfig.licenseKey ? handsontableConfig.licenseKey : 'non-commercial-and-evaluation';
    return handsontableLicenseKey;
  }

  getSelectAllCheckBoxHtml(): string {
    let htmlText = `<input type='checkbox'`;
    htmlText += `class='checker ${this.isAllSkusSelectedState === 1 ? 'checked' : (this.isAllSkusSelectedState === 0 ? 'unchecked' : 'partial')}'>`;
    htmlText += `<span class='count-txt'>${this.selectedSkuCount}</span>`;
    return htmlText;
  }

  generateTable(tableData, hotTableInstance): void {
    const self = this;
    const handsontableLicenseKey = this.handsontableLicenseKey;
    const profitColumnIndex = self.tableSettingsWithColumnChooser.visibleColumns.findIndex((column) => column.name === 'profit');
    const profitColumnName = profitColumnIndex > 0 ? self.tableSettingsWithColumnChooser.visibleColumns[profitColumnIndex].displayName : '';
    hotTableInstance.updateSettings({
      viewportColumnRenderingOffset: 27,
      viewportRowRenderingOffset: 'auto',
      allowInsertColumn: false,
      allowInsertRow: false,
      allowRemoveColumn: false,
      allowRemoveRow: false,
      autoWrapRow: false,
      autoWrapCol: false,
      manualRowResize: true,
      manualRowMove: true,
      manualColumnMove: false,
      filters: self.tableSettingsWithColumnChooser.allowFiltersForHeaders,
      dropdownMenu: ['filter_by_value', 'filter_action_bar'],
      colHeaders: self.tableSettingsWithColumnChooser.visibleColumnHeaders,
      columns: self.tableSettingsWithColumnChooser.visibleColumns,
      height: document.documentElement.clientHeight - 300,
      rowHeights: 34,
      manualColumnResize: false,
      data: tableData,
      licenseKey: handsontableLicenseKey,
      nestedHeaders: [self.tableSettingsWithColumnChooser.groupHeaders, self.tableSettingsWithColumnChooser.visibleColumnHeaders],
      hiddenRows: {
        rows: [],
        indicators: false
      },
      collapsibleColumns: [{
        row: -2,
        col: self.tableSettingsWithColumnChooser.groupingsStartAtIndex,
        collapsible: true
      }, {
        row: -2,
        col: self.tableSettingsWithColumnChooser.inputsStartAtIndex,
        collapsible: true
      }, {
        row: -2,
        col: self.tableSettingsWithColumnChooser.outputsStartAtIndex,
        collapsible: true
      }],
      renderAllRows: true,
      fixedRowsBottom: 0,
      afterOnCellMouseDown: (event, coords, TD): void => {
        if (event.target.classList.contains('checker')) {
          event.preventDefault(); // prevent selection quirk
          event.stopImmediatePropagation();
          self.clearOutputFields();
          self.headerCheckbox();
          self.hotTableInstance.deselectCell();
        } else if (event && event.target && ((event.target.firstElementChild && event.target.firstElementChild.classList.contains('sif-column-chooser')) ||
          event.target.classList.contains('sif-column-chooser'))) {
          self.openColumnChooserDialog(event);
          self.hotTableInstance.deselectCell();
        } else if (event && event.target && ((event.target.firstElementChild &&
          event.target.firstElementChild.classList.contains('sif-gear')) || event.target.classList.contains('sif-gear'))) {
          self.openProfitDialog();
          self.hotTableInstance.deselectCell();
        } else if (coords.col === 2 && coords.row >= 0 && this.skuGroups && this.skuGroups.length) {
          self.expandCollapseRows(coords.row, coords.col);
        }
      },
      afterGetColHeader(index, th): void {
        if (index === 1) {
          th.innerHTML = self.getSelectAllCheckBoxHtml();
        } else if (profitColumnIndex > 0 && profitColumnIndex === index && th.textContent === profitColumnName) {
          if (self.checkProfitSettingEnable()) {
            const gearIcon = `<span class='sif sif-gear adjust-icon' title='${self.profitSettingsTooltip}'></span>`;
            th.innerHTML = `<span class="profit-align-text">${th.innerHTML}${gearIcon}</span>`;
            
          } else {
            const gearIcon = `<span class='sif sif-gear adjust-icon profit-inputs-disable' title='${self.profitSettingsTooltip}'></span>`;
            th.innerHTML = `<span class="profit-align-text">${th.innerHTML}${gearIcon}</span>`;
          }
        }
        const BUTTON_CLASS_NAME = 'changeType';
        const existingButton = th.querySelector('.' + BUTTON_CLASS_NAME);
        if (!this.enabled) {
          if (existingButton) {
            if (Object.prototype.toString.call(this.getSettings().filters) === '[object Array]'
              && this.getSettings().filters.indexOf(index) === -1) {
              existingButton.parentNode.removeChild(existingButton);
            }
          }
          return;
        }
      },
      beforePaste: function (data, coords) {
        for (const coord of coords) {
          for (let i = coord.startRow, j = 0; i <= coord.startRow + data.length - 1; i += 1, j += 1) {
            for (let k = coord.startCol, l = 0; k <= coord.startCol + data[j].length - 1; k += 1, l += 1) {
              const cellMeta = this.getCellMeta(i, k);
              const type = cellMeta.type;
              let parsedData;
              if (type === 'checkbox') {
                parsedData = data[j][l] === '1' ? 1 : 0;
              } else {
                parsedData = data[j][l];
              }
              if (!cellMeta.readOnly) {
                this.setDataAtCell(i, k, parsedData);
              }
            }
          }
        }
        return false;
      },
      afterValidate: (isValid, value, row, prop, source): void => {
        self.afterValidate(hotTableInstance, isValid, value, row, prop, source);
      },
      /*
        Need to update the original data source because handsontable is in a different component
        whose data is passed in as an input. Since it's one way data binding, the changes
        that have been made to the table are not reflected in original data source.
        An additional logic to update the original data source is written below
      */
      afterCreateRow: (...args): void => {
        self.updateDataSource();
      },
      afterRemoveRow: (...args): void => {
        self.updateDataSource();
      },
      beforeColumnCollapse: (): void => {
        self.toggleCurrentCell();
      },
      beforeColumnExpand: (): void => {
        self.toggleCurrentCell();
      },
      afterColumnCollapse: (currentCollapsedColumns: number[], destinationCollapsedColumns: number[],
                            collapsePossible: boolean, successfullyCollapsed: boolean) => {
        self.currentCollapsedColumns = destinationCollapsedColumns;
      },
      afterColumnExpand: (currentCollapsedColumns: number[], destinationCollapsedColumns: number[],
                          expandPossible: boolean, successfullyExpanded: boolean) => {
        self.currentCollapsedColumns = destinationCollapsedColumns;
      },
      beforeFilter: (conditions): boolean => {
        const plugin = self.hotTableInstance.getPlugin('hiddenRows');
        /**
         * show rows/selected brand rows before apply new filter
         */
        if (!self.activeGroup) {
          plugin.showRows(this.allFilteredRows);
        } else {
          const rowsToShow = this.showOnlyActiveGroupItems();
          const rowsToRemoveFromHiddenList = this.showOnlyActiveGroupItems(true);
          this.filterAllRowsToHide(rowsToRemoveFromHiddenList);
          plugin.showRows(rowsToShow);
        }
        /**
         *  get rows to hide based on new conditions and hide them using plugin
         */
        conditions.map((filter) => {
          if (self.filterState[filter.column] && self.filterState[filter.column].length > 0 &&
            self.allFilteredRows && self.allFilteredRows.length > 0) {
            self.allFilteredRows = self.allFilteredRows.filter(rowNumber =>
              self.filterState[(filter.column)].indexOf(rowNumber) < 0);
          }
          self.filterState[filter.column] = [];
          const hRows = self.getRowsByFilter(filter.column, filter.conditions[0].args[0]);
          plugin.hideRows(hRows);
        });
        Object.keys(self.filterState).forEach((column) => {
          const col = conditions.find(condition => condition.column === +column);
          if (!col) {
            this.filterAllRowsToHide(self.filterState[(column)]);
            self.filterState[column] = [];
          }
        });

        /**
         * remove hidden rows on no conditions
         */
        if (!conditions.length) {
          self.allFilteredRows = [];
          self.filterState = [];
        }
        self.hotTableInstance.render();
        return false;
      },
      /**
       * Checks and cleans up input values before they are applied to the underlying model.
       * Here the assumption is that any value updated(via paste or otherwise) will be of the format ##.## for price
       * and ##.##(%*) for distribution.
       * For price based values, any characters outside of the format will be removed for eg. currency symbols, thousand separators etc.
       * For distribution, if the value is in percent then its converted to absolute value.
       */
      beforeChange: (changes: CellChange[]): void => {
        if (!changes) {
          return;
        }
        const handsontable = self.hotTableInstance;
        const nonNumericInputRegex = /[^0-9\.]/ig;
        const inputCurrencyFields = new Set(['priceInput', 'promoPrice', 'specialPromoPrice', 'featurePrice', 'displayPrice', 'featureAndDisplayPrice']);
        const inputShareFields = new Set(['distribution', 'promoDistribution', 'featureDistribution', 'displayDistribution', 'featureAndDisplayDistribution']);
        const cleanValue = (val): string => {
          const parts = `${val}`.split('.').map(it => it.replace(nonNumericInputRegex, '')).filter(it => it.length);
          const decimals = parts.pop();
          return parts.length ? `${parts.join('')}.${decimals}` : decimals;
        };
        changes.forEach((change) => {
          const row = change[0];
          const propName = `${change[1]}`;
          const rowData = handsontable.getSourceDataAtRow(row);
          if (rowData.id) {
            const isContinuousPromo = rowData.promotions.length === 0;
            if (inputCurrencyFields.has(propName)) {
              if (propName !== 'promoPrice' || (propName === 'promoPrice' && isContinuousPromo)) {
                const newValue = change[3];
                change[3] = cleanValue(newValue);
              }
            } else if (inputShareFields.has(propName)) {
              let newValue = change[3];
              if (newValue[newValue.length - 1] === '%') {
                newValue = cleanValue(newValue.replace('%', ''));
                change[3] = (Number.parseFloat(newValue) * 1000) / (100 * 1000);
              } else {
                change[3] = cleanValue(newValue);
              }
            }
          }
        });
      },
      afterChange: (changes, source): void => {
        if (!changes) {
          return;
        }
        if (this.inputParametersChanged(changes)) {
          this.clearOutputFields();
        }
        changes.forEach((change) => {
          const row = change[0];
          const col = change[1];
          const oldVal = change[2];
          const newVal = change[3];
          const rowData = self.hotTableInstance.getSourceDataAtRow(row);
          if (col === 'isSelected') {
            self.setSelectAllCheckBoxState();
            this.hotTableInstance.render();
          } else if (col === 'promoPriceText') {
            if(newVal !== 'Special Price'){
              self.hotTableInstance.setDataAtRowProp(row, 'priceInput', rowData.reportingBasePrice);
            }
            const specialPromoPriceColumn = self.tableSettingsWithColumnChooser.visibleColumns.find(h => {
              return h.name === 'specialPromoPrice';
            });
            const specialPrice = numericRangeValidator(self.hotTableData[row]['specialPromoPrice'],
                self.hotTableData[row]['promoPriceMin'], self.hotTableData[row]['promoPriceMax']) ?
                self.hotTableData[row]['specialPromoPrice'] : self.hotTableData[row]['reportingBasePromo'] ?
                    self.hotTableData[row]['reportingBasePromo'] : self.hotTableData[row]['promoPriceMin'];
            if (specialPromoPriceColumn && oldVal === 'Special Price' && newVal !== 'Special Price') {
              self.hotTableInstance.setDataAtRowProp(row, 'specialPromoPrice', specialPrice);
            } else if (specialPromoPriceColumn && oldVal !== 'Special Price' && newVal === 'Special Price') {
              self.hotTableInstance.setDataAtRowProp(row, 'specialPromoPrice', specialPrice);
            } else if (oldVal === 'Special Price' && newVal !== 'Special Price') {
              self.hotTableData[row]['specialPromoPrice'] = specialPrice;
            } else if (oldVal !== 'Special Price' && newVal === 'Special Price') {
              self.hotTableData[row]['specialPromoPrice'] = specialPrice;
            }
          } else if (col === 'distribution' || col === 'promoDistribution') {
            let actualValue = newVal;
            if (valueHasPercentageAtTheEnd(newVal)) {
               actualValue = newVal.substr(0, newVal.length - 1);
              if (!isNaN(actualValue)) {
                self.hotTableInstance.setDataAtRowProp(row, col, roundTo(parseFloat(actualValue) / 100, 2));
              }
            }
            if (col === 'distribution' && !(parseFloat(newVal) > 0)) {
              self.hotTableInstance.setDataAtRowProp(row, 'isSelected', false);
            }
            if (col === 'promoDistribution' && oldVal !== newVal && (self.metaData.promo === 'both' || self.metaData.promo === 'discrete') && actualValue > 0) {
              if (rowData.promoPriceText !== 'Special Price') {
                self.hotTableInstance.setDataAtRowProp(row, 'priceInput', rowData.reportingBasePrice);
              }
            }
          } else if (col === 'priceInput' && oldVal !== newVal && newVal !== rowData.reportingBasePrice && newVal) {
            if (self.metaData.promo === 'both') {
              if(rowData.continuousMap !== -1){
                self.hotTableInstance.setDataAtRowProp(row, 'promoPriceText', 'Special Price');
              }else{
                self.hotTableInstance.setDataAtRowProp(row, 'promoDistribution', 0);
              }
            } else if(self.metaData.promo === 'discrete'){
              self.hotTableInstance.setDataAtRowProp(row, 'promoDistribution', 0);
            }
          }
        });
        self.updateDataSource();
      },
    });
  }

  /**
   * Returns true if any of the input parameters in the sku config table is changed.
   */
  inputParametersChanged(changes: CellChange[]): boolean {
    const inputParameters = [
      'isSelected',
      'priceInput', // regular price
      'distribution',
      'featurePrice', 'displayPrice', 'featureAndDisplayPrice', //plug & play
      'promoPrice',
      'promoPriceText',
      'specialPromoPrice',
      'promoDistribution', // time on promo
      'featureDistribution', 'displayDistribution', 'featureAndDisplayDistribution', //plug & play
      'baseSize'
    ];
    // Filter out all the group level and brand level rows. We are interested only on changes made to the actual sku config data
    const skuConfigChanges = changes.filter((change) => {
      const rowData = this.hotTableInstance.getSourceDataAtRow(change[0]);
      return rowData && !(rowData.isGroupLabel || rowData.isBrand);
    });
    const inputChange = skuConfigChanges.find((change) => {
      return inputParameters.indexOf(String(change[1])) !== -1 && ((change[2] || change[3]) && String(change[2]) !== String(change[3]));
    });
    return !!inputChange;
  }

  /**
   * used to handle floating cell which is in edit state, on click of collapse and expand columns
   */
  toggleCurrentCell(): void {
    if (this.activeGroup) {
      this.hotTableInstance.setDataAtCell(0, 0, null);
    } else {
      this.hotTableInstance.setDataAtCell(0, 0, 1);
    }
  }

  afterValidate(hotInstance, isValid, value, row, prop, source): void {
    if (source !== 'loadData') {
      if (!isValid) {
        if (!this.invalidCells.has(row)) {
          this.invalidCells.set(row, new Set<number>());
        }
        this.invalidCells.get(row).add(prop);
      } else if (this.invalidCells.has(row)) {
        this.invalidCells.get(row).delete(prop);
        if (this.invalidCells.get(row).size === 0) {
          this.invalidCells.delete(row);
        }
      }
      this.setErrorMessages();
      this.errorMessageEvent.emit({
        nonFormErrorMessages: this.errorMessages,
      });
    }
  }

  updateDataSource(): void {
    this.tableChangesEvent.emit(this.hotTableInstance.getSourceData().filter(it => !(it.isGroupLabel || it.isBrand)));
  }

  setErrorMessages(): void {
    this.errorMessages = [];
    const it = this.invalidCells.values();
    let result = it.next();
    while (!result.done) {
      this.errorMessages.push('One or more cells has invalid data please check');
      result = it.next();
    }
  }

  makeCopyOfTableSettings(): void {
    this.tableSettings.allowFiltersForHeaders.forEach(h => {
      this.tableSettingsWithColumnChooser.allowFiltersForHeaders.push(h);
    });
    this.tableSettings.groupHeaders.forEach(g => {
      this.tableSettingsWithColumnChooser.groupHeaders.push({label: g.label, colspan: g.colspan, type: g.type});
    });
    this.tableSettings.columnHeaders.forEach(c => {
      this.tableSettingsWithColumnChooser.visibleColumnHeaders.push(c);
    });
    this.tableSettings.columns.forEach(c => {
      this.tableSettingsWithColumnChooser.visibleColumns.push(c);
    });
  }

  /**
   *
   * @param  type which is columnChooser type either GROUPS/INPUTS/OUTPUTS
   * returns number of columns hidden due to user configurations
   */
  removeColumnsWhichAreNotSelected(type: string, configurations: any): number {
    const columnGroupHeader = this.tableSettingsWithColumnChooser.groupHeaders.find(h => h.type === type);
    let noOfHiddenColumns = 0;

    this.tableSettings.columns.forEach((column) => {
      const columnChooser = configurations.find(configuration => configuration.name === column.name);
      if (columnChooser && !columnChooser.showInColumn) {
        const index = this.tableSettingsWithColumnChooser.visibleColumns.findIndex(a => a.name === columnChooser.name);
        this.tableSettingsWithColumnChooser.visibleColumns.splice(index, 1);
        this.tableSettingsWithColumnChooser.visibleColumnHeaders.splice(index, 1);
        if (type === 'GROUPS') {
          this.tableSettingsWithColumnChooser.allowFiltersForHeaders.splice(this.tableSettingsWithColumnChooser.visibleColumnHeaders.indexOf(index));
        }
        noOfHiddenColumns++;
      }
    });

    if (columnGroupHeader) {
      columnGroupHeader.colspan = columnGroupHeader.colspan - noOfHiddenColumns;
      if (!columnGroupHeader.colspan) {
        const headerIndex = this.tableSettingsWithColumnChooser.groupHeaders.indexOf(columnGroupHeader);
        this.tableSettingsWithColumnChooser.groupHeaders.splice(headerIndex, 1);
      }
    }

    return noOfHiddenColumns;
  }

  /**
   * Hides output column based on user preference and population filter selections.
   * */
  hideOutputColumns(): void {
    const columnGroupHeader = this.tableSettingsWithColumnChooser.groupHeaders.find(h => h.type === 'OUTPUTS');
    const outputColumnsToHide = new Set([...this.outputColumnsToHideOnFilterToggle(), ...this.outputColumnsToHideBasedOnUserConfigurations(),...this.outputColumnsToHideOnAbsolutesIndicesOnly()]);
    const visibleColumnNames = this.tableSettingsWithColumnChooser.visibleColumns.map(it => it.name);
    let noOfColumnsHidden = 0;
    visibleColumnNames.forEach((columnName, index) => {
      const column = this.tableSettingsWithColumnChooser.visibleColumns.find(it => it.name === columnName);
      if (index >= this.tableSettingsWithColumnChooser.outputsStartAtIndex) {
        if (outputColumnsToHide.has(column.name)) {
          const index = this.tableSettingsWithColumnChooser.visibleColumns.findIndex(a => a.name === column.name);
          this.tableSettingsWithColumnChooser.visibleColumns.splice(index, 1);
          this.tableSettingsWithColumnChooser.visibleColumnHeaders.splice(index, 1);
          noOfColumnsHidden++;
        } else {
          if (this.tableSettingsWithColumnChooser.visibleColumns.indexOf(column) === -1) {
            this.tableSettingsWithColumnChooser.visibleColumns.splice(index, 0, column);
            this.tableSettingsWithColumnChooser.visibleColumnHeaders.splice(index, 0, column.displayName);
          }
        }
      }
    });
    if (columnGroupHeader) {
      columnGroupHeader.colspan = columnGroupHeader.colspan - noOfColumnsHidden;
      if (!columnGroupHeader.colspan) {
        const headerIndex = this.tableSettingsWithColumnChooser.groupHeaders.indexOf(columnGroupHeader);
        this.tableSettingsWithColumnChooser.groupHeaders.splice(headerIndex, 1);
      }
    }
  }

  /**
   * Returns set of column names that should be hidden when population filter is toggled.
   * */
  outputColumnsToHideOnAbsolutesIndicesOnly(): Set<string> {
    const shareFields = ['unitsShare', 'unitsPromoShare', 'revenueShare', 'equivalizedVolumeShare', 'psShare'];
    const outputConfigurations = this.metaData.outputConfigurations;
    if (outputConfigurations && outputConfigurations['units'] && outputConfigurations['units']['showAbsolutesIndices']) {
      return Object.keys(outputConfigurations).reduce((acc: Set<string>, outputField: string) => {
        if (outputConfigurations[outputField]['showAbsolutesIndices'] && shareFields.indexOf(outputField) === -1) {
          acc.add(outputField);
        }
        return acc;
      }, new Set([]));
    }
    return new Set([]);
  }


  /**
   * Returns set of column names that should be hidden when absoluetesIndicesSelected.
   * */
  outputColumnsToHideOnFilterToggle(): Set<string> {
    if (this.hasUnSelectedNonSampleFilter) {
      const outputConfigurations = this.metaData.outputConfigurations;
      return Object.keys(outputConfigurations).reduce((acc: Set<string>, outputField: string) => {
        if (!outputConfigurations[outputField].showOnNonSampleFilterToggle) {
          acc.add(outputField);
        }
        return acc;
      }, new Set([]));
    }
    return new Set([]);
  }

  /**
   * Returns set of column names that should be hidden based on user preference.
   * */
  outputColumnsToHideBasedOnUserConfigurations(): Set<string> {
    const configurations = this.skuConfigColumnChooser.configurations['hideOutputs'];
    return this.tableSettings.columns.reduce((acc: Set<string>, column) => {
      const columnChooser = configurations.find(configuration => configuration.name === column.name);
      if (columnChooser && !columnChooser.showInColumn) {
        acc.add(column.name);
      }
      return acc;
    }, new Set([]));
  }

  applyUserConfigurationsForSkuConfigTable(): void {
    this.makeCopyOfTableSettings();
    this.setGroupingsColumnChooser();
    let hiddenColumnCount = this.removeColumnsWhichAreNotSelected('GROUPS', this.skuConfigColumnChooser.configurations['hideGroups']);
    this.tableSettingsWithColumnChooser.inputsStartAtIndex = this.tableSettings.inputsColumnStartIndex - hiddenColumnCount;
    this.tableSettingsWithColumnChooser.outputsStartAtIndex = this.tableSettings.outPutColumnStartIndex - hiddenColumnCount;
    this.setInputsColumnChooser();
    hiddenColumnCount = this.removeColumnsWhichAreNotSelected('INPUTS', this.skuConfigColumnChooser.configurations['hideInputs']);
    this.tableSettingsWithColumnChooser.outputsStartAtIndex = this.tableSettingsWithColumnChooser.outputsStartAtIndex - hiddenColumnCount;
    this.setOutputsColumnChooser();
    this.hideOutputColumns();
    //this.removeColumnsWhichAreNotSelected('OUTPUTS', this.skuConfigColumnChooser.configurations['hideOutputs']);
  }

  removeFilterOnReportingNameColumn(): void {
    const reportColumnFilterIndex = this.tableSettingsWithColumnChooser.allowFiltersForHeaders.indexOf(2);
    this.tableSettingsWithColumnChooser.allowFiltersForHeaders.splice(reportColumnFilterIndex, 1);
  }

  setGroupingsColumnChooser(): void {
    const configType = this.appConstantsService.skuConfigColumnChooserConfigType;
    this.skuConfigColumnChooser = this.userConfigurationsService.getUserConfigurationsByType(configType);
    this.skuConfigColumnChooser = this.skuConfigColumnChooser ? this.skuConfigColumnChooser : new UserConfigurations();
    this.skuConfigColumnChooser.configurations = this.skuConfigColumnChooser.configurations ? this.skuConfigColumnChooser.configurations : {
      hideGroups: [],
      hideInputs: [],
      hideOutputs: []
    };
    if (this.skuGroups.length > 0) {
      this.skuGroups.forEach((skuGroup: SkuGroup) => {
        const groupChooser = this.skuConfigColumnChooser.configurations['hideGroups'].find((c) => {
          return c.name === skuGroup.name;
        });
        if (!groupChooser) {
          this.skuConfigColumnChooser.configurations['hideGroups'].push({
            displayName: skuGroup.displayName,
            name: skuGroup.name,
            showInRow: true,
            showInColumn: true
          });
        } else {
          groupChooser.displayName = skuGroup.displayName;
        }
      });
    }
    this.skuConfigColumnChooser.configurations['hideGroups'] = this.orderColumnsForColumnChooser(this.skuConfigColumnChooser.configurations['hideGroups']);
  }

  /**
   *
   * @param data columns to be arranged
   */
  orderColumnsForColumnChooser(data: any): any {
    const columnData = [];
    this.tableSettingsWithColumnChooser.visibleColumns.forEach((column) => {
      const columnChooser = data.find((c) => {
        return c.name === column.name;
      });
      if (columnChooser) {
        columnData.push(columnChooser);
      }
    });
    return columnData;
  }

  setInputsColumnChooser(): void {
    this.tableSettingsWithColumnChooser.visibleColumns.forEach((column: any, index: number) => {
      if (index >= this.tableSettingsWithColumnChooser.inputsStartAtIndex &&
        index < this.tableSettingsWithColumnChooser.outputsStartAtIndex) {
        const inputColumnChooser = this.skuConfigColumnChooser.configurations['hideInputs'].find((c) => {
          return c.name === column.name;
        });
        if (!inputColumnChooser || ((this.metaData.promo === 'both' || this.metaData.promo === 'discrete' ) &&(column.name === 'promoPrice' || column.name === 'promoDistribution' || column.name === 'priceInput'))) {
          this.skuConfigColumnChooser.configurations['hideInputs'].push({
            displayName: column.displayName,
            name: column.name,
            showInColumn: true
          });
        }
      }
    });
    this.skuConfigColumnChooser.configurations['hideInputs'] = this.orderColumnsForColumnChooser(this.skuConfigColumnChooser.configurations['hideInputs']);
  }

  setOutputsColumnChooser(): void {
    this.tableSettingsWithColumnChooser.visibleColumns.forEach((column: any, index: number) => {
      if (index >= this.tableSettingsWithColumnChooser.outputsStartAtIndex) {
        const outputColumnChooser = this.skuConfigColumnChooser.configurations['hideOutputs'].find((c) => {
          return c.name === column.name;
        });
        if (!outputColumnChooser) {
          this.skuConfigColumnChooser.configurations['hideOutputs'].push({
            displayName: column.displayName,
            name: column.name,
            showInColumn: true,
            showIndices: true
          });
        }
      }
    });
    this.skuConfigColumnChooser.configurations['hideOutputs'] = this.orderColumnsForColumnChooser(
      this.skuConfigColumnChooser.configurations['hideOutputs']);
  }

  applyColumnSelectionChange(): void {
    if (this.skuConfigColumnChooser.id) {
      this.userConfigurationsService.updateUserConfiguration(this.skuConfigColumnChooser).subscribe(userConfigurations => {
        this.userConfigurationsService.userConfigurations = userConfigurations;
        this.loadHotTable();
      });
    } else {
      this.skuConfigColumnChooser.configType = this.appConstantsService.skuConfigColumnChooserConfigType;
      this.skuConfigColumnChooser.projectId = this.metaData.projectId;
      this.skuConfigColumnChooser.modelRunId = this.metaData.modelRunId;
      this.userConfigurationsService.createNewUserConfiguration(this.skuConfigColumnChooser).subscribe(userConfigurations => {
        this.userConfigurationsService.userConfigurations = userConfigurations;
        this.loadHotTable();
      });
    }
  }

  openColumnChooserDialog(event): void {
    const uncheckedAndDisabledColumns = new Set([...this.outputColumnsToHideOnFilterToggle(), ...this.outputColumnsToHideOnAbsolutesIndicesOnly()]);
    let outputsOnNonSampleFilterToggle = this.metaDataService.outputsOnNonSampleFilterToggle(this.metaData);
    if ((!this.hasUnSelectedNonSampleFilter || outputsOnNonSampleFilterToggle === this.appConstantsService.SHARES_AND_ABSOLUTES) && uncheckedAndDisabledColumns && uncheckedAndDisabledColumns.size) {
      outputsOnNonSampleFilterToggle = this.appConstantsService.FOR_ABSOLUTES_INDICES_ONLY;
    }
    const targetAttr = event.target.getBoundingClientRect();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.backdropClass = 'cdk-overlay-transparent-backdrop';
    if (event.target.classList.contains('groups')) {
      dialogConfig.data = {
        columnData: this.skuConfigColumnChooser.configurations['hideGroups'],
        customDialogOpen: true,
        groupHeaderLabel: 'groups'
      };
    } else if (event.target.classList.contains('inputs')) {
      dialogConfig.data = {
        columnData: this.skuConfigColumnChooser.configurations['hideInputs'],
        customDialogOpen: true,
        groupHeaderLabel: 'inputs'
      };
      if(this.metaData.promo === 'both' || this.metaData.promo === 'discrete'){
        dialogConfig.data.columnData.forEach((column) => {
          if (column.name === 'promoPrice' || column.name === 'promoDistribution' || column.name === 'priceInput') {
            column.showAlways = true;
            column.showInColumn = true;
          }
        });
      }
    } else if (event.target.classList.contains('outputs')) {
      dialogConfig.data = {
        columnData: this.skuConfigColumnChooser.configurations['hideOutputs'],
        customDialogOpen: true,
        groupHeaderLabel: 'outputs',
        uncheckedAndDisabledColumns,
        outputsOnNonSampleFilterToggle
      };
    }
    dialogConfig.position = {
      top: `${targetAttr.y + targetAttr.height + 5}px`,
      left: `${targetAttr.x - targetAttr.width - 200}px`
    };
    this.dialogRef = this.dialog.open(ColumnChooserComponent, dialogConfig);
    this.dialogRef.afterClosed().subscribe(() => {
      if (this.dialogRef.componentInstance.hasChanges) {
        this.applyColumnSelectionChange();
        this.currentCollapsedColumns = [];
        this.dialogRef = null;
      }
    });
  }

  headerCheckbox(): void {
    if (this.tableSettingsWithColumnChooser.visibleColumns[1].readOnly) {
      return;
    }
    if (this.isAllSkusSelectedState === 0) {
      this.isAllSkusSelectedState = 1;
      this.hotTableData.forEach((skuConfig: any) => {
        skuConfig['isSelected'] = skuConfig.isBrand || skuConfig.isGroupLabel ? skuConfig['isSelected'] : 1;
      });
    } else {
      this.isAllSkusSelectedState = 0;
      this.hotTableData.forEach((skuConfig: any) => {
        skuConfig['isSelected'] = skuConfig.isBrand || skuConfig.isGroupLabel ? skuConfig['isSelected'] : 0;
      });
    }
    this.updateDataSource();
    this.selectedSkuCount = this.hotTableData.filter((h) => {
      return h.skuId > 0 && h.isSelected;
    }).length;
    this.disableSimulateAndSaveOnSelectionChange();
    this.hotTableInstance.render();
  }

  disableSimulateAndSaveOnSelectionChange(): void {
    if (!this.selectedSkuCount) {
      this.errorMessageEvent.emit({
        nonFormErrorMessages: 'error',
      });
    } else {
      this.errorMessageEvent.emit({
        nonFormErrorMessages: '',
      });
    }
  }

  openProfitDialog(): void {
    if (this.checkProfitSettingEnable()) {
      this.profitInputClickEvent.emit();
    }
  }

  checkProfitSettingEnable(): boolean {
    let profitSettingEnabled = false;
    const scenario = this.scenarioService.getScenario(this.scenarioId);
    const scenarioOwner = this.projectService.getCollaboratorById(scenario.createdBy);
    const loggedInUser = this.authProxyService.user;
    this.profitSettingsTooltip = 'Adjust Profit';
    /*
        if scenario owned by bases and shared with client and on client login, client is able to access the profit gear Icon.
        if scenario owned by bases and bases user login, if the client modified the profit inputs ,
           Bases should not able to access the profit gear Icon and shows(Profit inputs saved by client users are confidential).
        if scenario1 owned by client1 shared to another client2 and client2 Duplicates and creates new scenario2 ,
          the client1 have profit inputs in  scenario1 the profit inputs in scenario2 as well not accessible for client2 and shows(Profit inputs saved by other users are confidential).
        if none of the above case happens, it depends on editMode
     */
    if (scenarioOwner) {
      if (scenarioOwner.isInternalUser && !loggedInUser.isInternalUser) {
        profitSettingEnabled = true;
      } else if (scenarioOwner.isInternalUser && loggedInUser.isInternalUser && this.isScenarioOwnedByBasesAndProfitModifiedByClient()) {
        this.profitSettingsTooltip = 'Profit inputs saved by client users are confidential';
      } else if (!scenarioOwner.isInternalUser && (scenario.profitInputModifiedBy && (!this.isProfitModifiedByBases(scenario) && scenario.profitInputModifiedBy !== loggedInUser.userId))) {
        this.profitSettingsTooltip = 'Profit inputs saved by other users are confidential';
      } else {
        profitSettingEnabled = this.editMode;
      }
    } else {
      profitSettingEnabled = this.editMode;
    }
    return profitSettingEnabled;
  }

  isProfitModifiedByBases(scenario: Scenario): boolean {
      const profitInputModifier = this.projectService.getCollaboratorById(scenario.profitInputModifiedBy);
      return profitInputModifier ? profitInputModifier.isInternalUser : false;
   }

  isScenarioOwnedByBasesAndProfitModifiedByClient(): boolean {
    // if profitInputModifiedBy is not an internal/Bases user return true else false.
    const scenario = this.scenarioService.getScenario(this.scenarioId);
    const profitInputModifier = this.projectService.getCollaboratorById(scenario.profitInputModifiedBy);
    return profitInputModifier ? !profitInputModifier.isInternalUser : false;
  }
}
