<div class="create-list">
    <div class="table-data">
        <table>
            <thead>
            <tr>
                <th>ID</th>
                <th>Run Name</th>
                <th>Run Description</th>
                <th>Scenario</th>
                <th>Sample Information</th>
                <th>Metric</th>
                <th>Status</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let report of reports;">
                <td>{{report.jobId}}</td>
                <td>{{report.name}}</td>
                <td>{{report.description}}</td>
                <td>{{report.scenarioId | getScenarioName:projectId:modelRunId}}</td>
                <td [appReportSampleInfo]="report"></td>
                <td>{{report.outputConfigMetric | getMetricDisplayName:metaData}}</td>
                <td [appReportStatus]="report"></td>
                <td>
                    <button class="delete-btn" mat-icon-button aria-label="Delete Optimization Report"
                            (click)="deleteOptimizationReport(report)"><span class="sif sif-delete"></span></button>
                    <button class="download-btn" mat-icon-button aria-label="Download Optimization Report"
                            (click)="downloadOptimizationReport(report)"
                            [disabled]="report.status!=='Completed'? true : null"><span class="sif sif-download"></span>
                    </button>
                    <button class="copy-btn" mat-icon-button aria-label="Copy Optimization Report"
                            (click)="duplicate(report)"><span class="sif sif-copy"></span></button>
                </td>
            </tr>
            <tr *ngIf="reports.length===0">
                <td colspan="5" class="no-optimize">No Optimization Runs currently exist. Press “+ Optimization Run” to
                    begin adding runs to the queue.
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <button mat-button (click)="createOptimizationRun()" class="add-report optimization-run"><span
            class="sif sif-add"></span><span>OPTIMIZATION RUN</span></button>
</div>
