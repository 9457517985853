<div class="mr-t-2 calibration-container">
  <div class="tool-bar create-calibration">
    <div class="calibration-text">
      <button mat-icon-button aria-label="back-button" class="back-btn" (click)="returnToScenarios()"><span class="sif sif-arrow-w"></span></button>
      <h2>Database Calibration</h2>
      <span class="separator-vert"></span>
      <span [ngClass]="calibrationStatusState"><span [innerHTML]="calibrationStatus"></span></span>
    </div>
    <div style="margin-left: auto;">
      <mat-slide-toggle
              [id]="'calibration-lock-toggle'"
              [checked]="calibrationLocked"
              [disabled]="disableLockCalibration"
              [matTooltip]="calibrationLocked?'Edit Mode':'Read-only Mode'"
              (toggleChange)="toggleCalibrationLock()">
      </mat-slide-toggle>
    </div>
    <span class="separator-vert"></span>
    <div class="export-menu">
      <button mat-button [ngClass]="t.menuOpen? 'active':'inActive'"
              #t="matMenuTrigger" [matMenuTriggerFor]="menu">
        <span class="sif sif-ellipsis-vertical"></span>
      </button>
      <mat-menu #menu="matMenu">
        <span [matTooltip]="calibration.statusCode < 6 ? 'Calibration Not Ready': ''">
        <button [disabled]="calibration.statusCode < 6"  mat-menu-item (click)="exportCalibrationData()">Export to Excel</button>
        <button [disabled]="calibration.statusCode < 6"  mat-menu-item (click)="exportProjectData()">Export Project Data</button>
        </span>
      </mat-menu>
    </div>
  </div>

  <app-message-prompt *ngIf="lockMessage" [message]="lockMessage" [type]="'warn'"></app-message-prompt>

  <div *ngIf="calibration.statusCode < 6 ; then preStep2Block else step2Block"></div>
  <ng-template #preStep2Block>
    <app-disable-ui class="blocker" [disable]="!calibrationLocked || lockMessage || calibrating">
      <mat-card class="mat-card-border">
      <div class="toolbar-container">
        <div>
          <div class="show-inputs" >
            <p  [matTooltip]="populationFilterToolTip" matTooltipClass="calibration-filter-tooltip" class="n-size text" >{{nSizeText}}</p>
            <span class="separator-vert"></span>
          </div>
          <div *ngIf="metaData.hasEqVolume" class="show-inputs" [matTooltip]="disableCalibrationBenchmark?disableVolumeTypeReason:null">
            <span>Volume Type: </span>
            <app-single-select-dropdown [disable]="disableCalibrationBenchmark" [options]="volumeTypeOptions" [selectedOption]="selectedVolumeTypeOption" (optionChanged)="volumeTypeOptionChange($event)"></app-single-select-dropdown>
          </div>
          <div *ngIf="showBenchMark" class="show-inputs">
            <app-calibration-benchmark [disable]="disableCalibrationBenchmark" [calibration]="calibration" (valueChanged)="applyOptions()"></app-calibration-benchmark>
          </div>
        </div>
        <div class="action-buttons">
          <!--<button mat-icon-button class="sif-buttons"><span class="sif sif-reset"></span></button>-->
          <button *ngIf="calibration.statusCode>=3 && enableUploadWeeklyData" mat-icon-button class="sif-buttons" title="Upload weekly data file" ngxFilePicker accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" (filePick)="uploadWeeklyDataFile($event)" ><span class="sif sif-upload"></span></button>
          <button *ngIf="calibration.statusCode>=3 && enableUploadWeeklyData" [disabled]="!calibration.weeklyDataFileClaimCheckId" mat-icon-button class="sif-buttons" title="Download weekly data file" (click)="downloadWeeklyData()"><span class="sif sif-download"></span></button>
          <app-multi-select-dropdown multiselct1 [additionalColumnOptions]="additionalColumnOptions" (shareIndividualCheckedList)="shareIndividualCheckedList($event)">
          </app-multi-select-dropdown>
          <span class="separator-vert"></span>
          <button *ngIf="isCalibrationInitiated" mat-icon-button class="sif-buttons"  (click)="delete()"><span class="sif sif-delete"></span></button>
          <button *ngIf="calibration.statusCode<3" class="close-btn apply-btn" mat-flat-button color="secondary" title="{{disableApply?disableApplyReason: ''}}" [disabled]="disableApply" (click)="calibrateStep1()">Apply</button>
          <button *ngIf="calibration.statusCode>=3" class="start-run-btn calibrate-btn" mat-flat-button color="primary"  title="{{disableCalibration?disableCalibrateReason: ''}}" [disabled]="disableCalibration" (click)="calibrateStep2()">Calibrate</button>
        </div>
      </div>
      <div id="hot-table" class="hot-table-container">
        <div class="hot-table sku-config-table hot-calibration-table">
          <ng-template [ngIf]="dataSet && dataSet.length">
            <app-calibration-config-table
                    [hotTableData]="dataSet"
                    [cellRenderers]="cellRenderers"
                    [tableSettings]="tableSettings"
                    [metaData]="metaData"
                    [skuGroups]="skuGroups"
                    [scenarios]="scenarios"
                    [isCalibrationInitiated]="isCalibrationInitiated"
                    [scenarioSkuConfigs]="scenarioSkuConfigs"
                    (errorMessageEvent)="tableErrorEventHandler($event)"
                    (tableChangesEvent)="tableDataChangeEventHandler($event)"
                    [reloadTableSubject]="reloadTable"
                    [calibrationStatus]="calibration.statusCode"
            ></app-calibration-config-table>
          </ng-template>
        </div>
      </div>
    </mat-card>
    </app-disable-ui>
  </ng-template>
  <ng-template #step2Block>
    <app-disable-ui class="blocker" [disable]="!calibrationLocked || lockMessage || calibrating">
      <mat-card>
      <div class="toolbar-container">
        <div class="dropdown-container">
            <div class="collapsible" @bodyExpansion *ngIf="collapsed">
            <div class="show-inputs" *ngIf="scenarioDropdownShow">
              <app-scenario-group-filter id="selectDropDown"
                                         [enableActionMenu]=true
                                         [placeholder]="'Select Base Scenario'"
                                         specificattr (dropDownChanged)="changeScenario($event)" (filter)="search($event)"
                                         [referenceScenarioData]="referenceScenarioData"
                                         [scenarioGroupsData]="scenarioGroupsData"
                                         [groupItemStatus]=false
                                         [enableActionMenu]=false
                                         [selectedScenarioIds]="selectedScenarioId">
              </app-scenario-group-filter>
            </div>
          </div>
          <div class="filters-container">
            <div class="show-inputs" >
              <p  [matTooltip]="populationFilterToolTip" matTooltipClass="calibration-filter-tooltip" class="n-size text" >{{nSizeText}}</p>
              <span class="separator-vert"></span>
            </div>
            <div *ngIf="metaData.hasEqVolume" class="show-inputs" [matTooltip]="disableCalibrationBenchmark?disableVolumeTypeReason:null">
              <span>Volume Type: </span>
              <app-single-select-dropdown [disable]="disableCalibrationBenchmark" [options]="volumeTypeOptions" [selectedOption]="selectedVolumeTypeOption" (optionChanged)="volumeTypeOptionChange($event)"></app-single-select-dropdown>
            </div>
            <div *ngIf="showBenchMark" class="show-inputs">
              <app-calibration-benchmark [disable]="disableCalibrationBenchmark" [calibration]="calibration" (valueChanged)="applyOptions()"></app-calibration-benchmark>
            </div>
            <mat-radio-group class="calibrate-radio-group radio-change" *ngIf="hasCorrelatedSkus">
              <mat-radio-button
                      class="calibration-radio-button"
                      *ngFor="let item of currentNewTypes"
                      [value]="item.id"
                      [checked]="item.selected"
                      (click)= "currentNewWarningModal(item)"
                      (change)="setCurrentType(item)" >
                {{item.displayName}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div>
          <div class="action-buttons">
            <button *ngIf="calibration.statusCode>=3 && enableUploadWeeklyData" [disabled]="!calibration.weeklyDataFileClaimCheckId" mat-icon-button class="sif-buttons" title="Download weekly data file" (click)="downloadWeeklyData()"><span class="sif sif-download"></span></button>
            <button mat-icon-button class="preview-button" *ngIf="showPreviewIcon" (click)="onClickPreviewResults()" [ngClass]="scenarioDropdownShow ? 'preview-active' : 'inActive'"><span class="sif sif-eye-enabled"></span></button><span class="separator-vert"></span>
            <button *ngIf="['discrete', 'continuous', 'both'].includes(metaData.promo)" mat-icon-button class="sif-buttons" matTooltip="Adjust Promotions" (click)="onPromotionClick()"><span
                    class="sif sif-pricetag"></span></button>
            <app-multi-select-dropdown multiselct1 [additionalColumnOptions]="additionalColumnOptions" (shareIndividualCheckedList)="shareIndividualCheckedList($event)"></app-multi-select-dropdown>
            <span class="separator-vert"></span>
            <!--<button mat-icon-button class="sif-buttons" matTooltip="Reset Calibration Data"><span class="sif sif-reset"></span></button>-->
            <button mat-icon-button class="sif-buttons" matTooltip="Clear Calibration Data" (click)="delete()"><span class="sif sif-clear"></span></button>
            <button mat-icon-button class="sif-buttons" [disabled]="disableSave" (click)="saveCalibration()"><span class="sif sif-save"></span></button>
            <button class="start-run-btn" mat-flat-button color="primary" title="{{disableCalibration?disableCalibrateReason: ''}}" [disabled]="disableCalibration" (click)="calibrateStep2()">Calibrate</button>
          </div>
        </div>
      </div>
        <app-message-prompt *ngIf="showProgress" [type]="'warn'" [message]="exportWarnMessage" (promptCloseEvent)="messageBarCloseEventHandler()"></app-message-prompt>
        <ng-template [ngIf]="dataSet && dataSet.length &&  selectedCalibrationView==='current'">
        <app-calibration-results
                [hotTableData]="dataSet"
                [cellRenderers]="cellRenderers"
                [tableSettings]="tableSettings"
                [metaData]="metaData"
                [skuGroups]="skuGroups"
                [scenarios]="scenarios"
                [scenarioSkuConfigs]="scenarioSkuConfigs"
                [scenarioColumnVisible] = scenarioColumnVisible
                (errorMessageEvent)="tableErrorEventHandler($event)"
                (tableChangesEvent)="tableDataChangeEventHandler($event)"
                [reloadCurrentResultsTableSubject]="reloadCurrentResultsTable"></app-calibration-results>
      </ng-template>
      <ng-template [ngIf]="newResultDataSet && !scenarioResultsTable && newResultDataSet.length &&  selectedCalibrationView==='new'">
        <app-calibration-results-new
                [hotTableData]="newResultDataSet"
                [cellRenderers]="cellRenderers"
                [tableSettings]="tableSettings"
                [metaData]="metaData"
                [skuGroups]="skuGroups"
                [scenarios]="scenarios"
                [scenarioColumnVisible] = scenarioColumnVisible
                [scenarioSkuConfigs]="scenarioSkuConfigs"
                (newErrorMessageEvent)="tableNewResultsErrorEventHandler($event)"
                (tableChangesEvent)="tableDataChangeEventHandler($event)"
                [reloadNewResultsTableSubject]="reloadNewResultsTable"></app-calibration-results-new>
      </ng-template>
        <ng-template [ngIf]="scenarioResultsTable">
          <app-calibration-scenario-results
                  [hotTableData]="newResultDataSet"
                  [cellRenderers]="cellRenderers"
                  [tableSettings]="tableSettings"
                  [metaData]="metaData"
                  [skuGroups]="skuGroups"
                  [scenarios]="scenarios"
                  [scenarioColumnVisible] = scenarioColumnVisible
                  [scenarioSkuConfigs]="scenarioSkuConfigs"
                  (errorMessageEvent)="tableErrorEventHandler($event)"
                  (tableChangesEvent)="tableDataChangeEventHandler($event)"
                  [reloadScenarioResultsTableSubject]="reloadScenarioResultsTable"></app-calibration-scenario-results>
        </ng-template>
    </mat-card>
    </app-disable-ui>
  </ng-template>
</div>
