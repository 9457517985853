<div class="dropdown-btn-section">
  <div class="tool-bar">
    <button mat-icon-button aria-label="back-button" class="back-btn" (click)="returnToScenarios()"><span class="sif sif-arrow-w"></span></button>
    <h2>Manage Scenarios</h2>
  </div>
  <div class="icon-btn-grp-right">
    <button class="delete-btn hide-options" mat-icon-button aria-label="Delete Scenarios"><span class="sif sif-delete"></span></button>
    <button class="menu-btn hide-options" mat-button [ngClass]="t.menuOpen ? 'active':'inActive'" #t="matMenuTrigger" [matMenuTriggerFor]="menu"><span class="sif sif-column-chooser"></span></button>
    <mat-menu #menu="matMenu" xPosition="before">
      <button mat-menu-item>Colin's Awesome Scenarios</button>
      <button mat-menu-item>Unassigned Scenarios</button>
    </mat-menu>
    <span matTooltip="Create Scenario Folder">
    <button id="create-scenario-folder" mat-icon-button aria-label="Group Scenarios" (click)="createScenarioGroupOpen = !createScenarioGroupOpen">
      <span class="sif sif-group"></span>
    </button>
    </span>
  </div>
</div>

<div class="manage-scenario-content">
<div class="scenario-tree-box">
  <div class="flex tree-header">
     <div class="width-30p">
       <button class="collapse-groups-btn" mat-icon-button aria-label="Expand/Collapse Groups" (click)="expandCollapseAll()"><span class="sif sif-shrink-vertical"></span></button>
       <mat-checkbox [disabled]="!isScenariosAvailable"
        [checked]="allSelected"
        [indeterminate]="indeterminate"
        (change)="toggleSelectAll($event)">
        </mat-checkbox>
         Scenario Name
     </div>
     <div class="width-40p">Description</div>
      <div class="width-20p">
          <app-ns-default-dropdown dropdwn [label]="scenarioOwnerLabel" class="dropdown-container">
              <ul class="input-list">
                  <li id="entityOwner" (click)="toggleOwnerAndModifiedBy(0)">Owner</li>
                  <li id="modifiedBy" (click)="toggleOwnerAndModifiedBy(1)">Modified By</li>
              </ul>
          </app-ns-default-dropdown>
      </div>
     <div class="width-10p">Last modified</div>
     <div class="flex-140"></div>
  </div>
  <mat-tree class="scenario-tree" [dataSource]="dataSource" [treeControl]="treeControl"
  cdkDropList (cdkDropListDropped)="drop($event)" [cdkDropListData]="dataSource.data" cdkDropListLockAxis="y"
  cdkDropListOrientation="vertical">
  <mat-tree-node [id]="node.id" class="child-node" *matTreeNodeDef="let node; when !grpNode;"
  matTreeNodeToggle matTreeNodePadding matTreeNodePaddingIndent="0" cdkDrag [cdkDragData]="node"
  (mouseenter)="dragHover($event,node)" (mouseleave)="dragHoverEnd($event)" (cdkDragStarted)="dragStart($event,node)"
  (cdkDragReleased)="dragEnd($event)" (cdkDragMoved)="dragMoved($event,node)">
  <div class="node-name" [ngClass]="{'error-node':node.hasError}">
     <div class="width-30p">
        <span class="sif sif-drag-handler" cdkDragHandle></span>
        <mat-checkbox class="child-checkbox"
        [checked]="node.isChecked"
        (change)="itemChanged(node,$event)"
        [disabled]="scenarioGroupService.isCalibrationScenarioGroup(getParentNode(node))"
        >
        </mat-checkbox>
       <div id="scenarioNameId" class="scenario-edit-cont"
            [ngClass]="{'warning':node.conditions.name.warning, 'error':node.conditions.name.error}">
           <div *ngIf="!scenarioService.isCalibrationScenario(node) && node.permissions.edit; else noScenarioEditPermission" class="input-hint-cont">
               <input matInput #scenarioName small type="text" [(ngModel)]="node.name" required="true"
                      (keyup)="validateName(node)" (change)="nodeChanged(node)">
               <mat-hint>{{scenarioName.value.length}} / {{this.scenarioNameLength}}</mat-hint>
           </div>
           <div>
               <ng-template #noScenarioEditPermission>
                   {{node.name}}
               </ng-template>
           </div>
         <mat-error *ngIf="node.conditions.name.error">
           {{node.conditions.name.error}}
         </mat-error>
       </div>
     </div>
     <div class="width-40p"><div id="scenarioDescId" class="scenario-edit-cont"
          [ngClass]="{'warning':node.conditions.description.warning, 'error':node.conditions.description.error}">
         <div *ngIf="!scenarioService.isCalibrationScenario(node) && node.permissions.edit; else noScenarioDescEditPermission" class="input-hint-cont">
             <input (mouseenter)="mouseOverText(node)" (mouseleave)="mouseLeaveText(node)" [id]="node.id + 'desc'" matInput #scenarioDesc small type="text" placeholder="--" [(ngModel)]="node.description"
                    (keyup)="validateDescription(node)" (change)="nodeChanged(node)">
             <mat-hint>{{scenarioDesc.value.length}} / {{this.scenarioDescLength}}</mat-hint>
         </div>
         <div>
             <ng-template #noScenarioDescEditPermission>
                 <span>{{node.description ? node.description: '--'}} </span>
             </ng-template>
         </div>
       <mat-error *ngIf="node.conditions.description.error">
         {{node.conditions.description.error}}
       </mat-error>
     </div>
     </div>
      <div *ngIf="scenarioOwnerToggle ;else modifiedByTemplate" class="width-20p">{{owner(node)}}</div>
      <ng-template #modifiedByTemplate>
          <div class="width-20p">{{modifiedBy(node)}}</div>
      </ng-template>
      <div class="width-10p">{{lastModified(node)}}</div>
     <div class="flex-140">
       <button [disabled]="scenarioService.isCalibrationScenario(node) || restrictCalibrationScenarios(getParentNode(node)) || !node.permissions.delete" id="delete-scenario-model" [title]="toolTip(node)"
               class="node-btn-group custom-btn" mat-icon-button (click)="deleteScenarioModel(node)"><span class="sif sif-delete"></span></button>
       <button [disabled]="scenarioService.isCalibrationScenario(node) || restrictCalibrationScenarios(getParentNode(node)) || !getParentNode(node).permissions.view"  [title]="toolTip(node)"
               id="scenario-group-add-remove" class="node-btn-group custom-group-btn" mat-icon-button  mat-button  [matMenuTriggerFor]="menu" (click)="loadScenarioGroupAssignment(node)" ><span class=" sif sif-column-chooser"></span></button>
       <mat-menu id="scenario-group-add-remove-menu" #menu="matMenu" xPosition="before" class="custom"  (close)="scenarioGroupsAssignmentClear()">
         <h4>Add/Remove from Folder(s)</h4>
        <div class="group-list-container">
         <div *ngFor="let scenarioGroupAssignment of scenarioGroupsAssignment" class="flex checkbox-row" (click)="$event.stopPropagation();">
           <mat-checkbox *ngIf="!scenarioGroupService.isUnAssignedScenariosGroup(scenarioGroupAssignment.scenarioGroup) && !scenarioGroupService.isCalibrationScenarioGroup(scenarioGroupAssignment.scenarioGroup)"
                         [disabled]="scenarioGroupAssignment.disable"
                         [id]="scenarioGroupAssignment.scenarioGroup.id"
                         [checked]="scenarioGroupAssignment.isChecked"
                         (change)="scenarioGroupAssignmentChanged(node, scenarioGroupAssignment, $event)">
             {{scenarioGroupAssignment.scenarioGroup.name}}
           </mat-checkbox>
             <mat-radio-button *ngIf="scenarioGroupService.isCalibrationScenarioGroup(scenarioGroupAssignment.scenarioGroup)"
                               [disabled]="scenarioGroupAssignment.disable"
                               [id]="scenarioGroupAssignment.scenarioGroup.id"
                               [checked]="scenarioGroupAssignment.isChecked"
                               (change)="calibrationScenarioGroupAssignment(node, $event)"
                                [matTooltip]="scenarioGroupAssignment.disable ? 'This folder is locked once calibration has started': ''">
                 {{scenarioGroupAssignment.scenarioGroup.name}}
             </mat-radio-button>
           <mat-radio-button *ngIf="scenarioGroupService.isUnAssignedScenariosGroup(scenarioGroupAssignment.scenarioGroup)"
                             [disabled]="scenarioGroupAssignment.disable"
                             [id]="scenarioGroupAssignment.scenarioGroup.id"
                             [checked]="scenarioGroupAssignment.isChecked"
                             (change)="unAssociatedScenarioGroupAssignment(node, $event)">
             {{scenarioGroupAssignment.scenarioGroup.name}}
           </mat-radio-button>
         </div>
        </div>
         <div class="action-buttons">
           <button id="scenario-assignment-dialog-cancel" mat-button mat-menu-close>Cancel</button>
           <button id="scenario-assignment-dialog-save" mat-raised-button color="primary" [disabled]="!scenarioGroupsAssignmentSave"
                   cdkFocusInitial (click)="onSaveScenarioAssigment(node)">Save</button>
         </div>
       </mat-menu>
     </div>
  </div>
  </mat-tree-node>
  <mat-tree-node [id]="node.id" class="parent-node" *matTreeNodeDef="let node; when: grpNode;"
  matTreeNodeToggle matTreeNodePadding matTreeNodePaddingIndent="0" cdkDrag [cdkDragData]="node" cdkDragBoundary=".scenario-tree"
  [ngClass]="{'no-child': !node.children.length, 'main-node': node.level===0}"
  (mouseenter)="dragHover($event, node)" (mouseleave)="dragHoverEnd($event)" (cdkDragStarted)="dragStart($event, node)"
  (cdkDragReleased)="dragEnd($event)" (cdkDragMoved)="dragMoved($event,node)">
  <div class="node-name" [ngClass]="{'error-node':node.hasError}">
   <div class="width-30p flex">
       <span  class="sif"
              [ngClass]="(!scenarioGroupService.isUnAssignedScenariosGroup(node) && !scenarioGroupService.isCalibrationScenarioGroup(node))? 'sif-drag-handler' : 'sif-drag-handler-no-show un-assigned-scenarios-drag-no-show'" cdkDragHandle></span>
   <button class="node-toggle" mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name"
            (click)="toggleNode(node)">
      <span class="{{treeControl.isExpanded(node) ? 'sif sif-chevron-s' : 'sif sif-chevron-e'}}"></span>
    </button>
     <div class="flex-group-name">
         <div class="scenario-edit-cont"
              [ngClass]="{'warning':node.conditions.name.warning, 'error':node.conditions.name.error}">
             <div *ngIf="(node.permissions.edit && ((!scenarioGroupService.isUnAssignedScenariosGroup(node) && !scenarioGroupService.isCalibrationScenarioGroup(node))
             || node.changed ));
       else noScenarioGroupEditPermission" class="input-hint-cont">
                 <input matInput #scenarioGroupName small type="text"
                        [(ngModel)]="node.name" required="true"
                        (keyup)="validateName(node)" (change)="nodeChanged(node)">
                 <mat-hint>{{scenarioGroupName.value.length}} / {{this.scenarioGroupNameLength}}</mat-hint>
             </div>
             <div>
                 <ng-template #noScenarioGroupEditPermission>{{node.name}}</ng-template>
             </div>
             <mat-error *ngIf="node.conditions.name.error">
                 {{node.conditions.name.error}}
             </mat-error>
         </div>
     </div>
   </div>
      <div class="width-40p"></div>
      <div *ngIf="(scenarioOwnerToggle && !scenarioGroupService.isUnAssignedScenariosGroup(node) && !scenarioGroupService.isCalibrationScenarioGroup(node));else modifiedByTemplate"
           class="width-20p scenario-group-owner">{{owner(node)}}</div>
      <ng-template #modifiedByTemplate>
      <div class="width-20p"></div>
      </ng-template>
      <div class="width-10p"></div>
     <div class="flex-140" *ngIf="(!scenarioGroupService.isUnAssignedScenariosGroup(node) && !scenarioGroupService.isCalibrationScenarioGroup(node))">
         <button id="delete-scenario-group-model" [disabled]="!(node.permissions.delete && node.children.length === 0)" class="node-btn-group empty-scenario-delbtn" mat-icon-button
                (click)="deleteScenarioGroupModel(node)">
          <span class="sif sif-delete"></span></button>
         <button id="share-scenario-group-model"
             [title]="runDataNotAvailable?runDataNotAvailableTitle:''"
             [disabled]="runDataNotAvailable || node.children.length === 0" mat-icon-button
             (click)="shareScenarioGroupModal(node)" class="node-share-btn custom-group-btn"><span
             class="sif sif-share"></span>
         </button>
     </div>
  </div>
  </mat-tree-node>
  </mat-tree>
</div>
  <div class="create-scenaro-group" [ngClass]="createScenarioGroupOpen ? 'flyout-menu-open' : 'flyout-menu-close'">
    <h3>
      Create Scenario Folder
      <button mat-icon-button aria-label="Close" (click)="createScenarioGroupOpen = !createScenarioGroupOpen"><span class="sif sif-close"></span></button>
    </h3>
    <div>
      <mat-form-field  id="create-scenario-folder-form" appearance="standard">
        <label>Name of Scenario Folder</label>
        <input id="create-scenario-folder-input" matInput #newScenarioGroupName class="mat-input-element" placeholder="Scenarios Folder Name"
               [ngClass]="{'warning':scenarioGroupValidations.name.warning,'error':scenarioGroupValidations.name.error}"
               [(ngModel)]="scenarioGroup.name"
               (keyup) ="validateScenarioGroup(scenarioGroup)">
        <div class="flex">
          <mat-error *ngIf="scenarioGroupValidations.name.error">
            {{scenarioGroupValidations.name.error}}
          </mat-error>
          <mat-hint>{{newScenarioGroupName.value.length}} / {{this.scenarioGroupNameLength}}</mat-hint>
        </div>
      </mat-form-field>
      <button id="save-scenario-folder" mat-flat-button [disabled] = '!createScenarioGroupSave' color="primary" (click)="createScenarioGroup()">Save</button>
    </div>
  </div>
</div>
