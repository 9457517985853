import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ModelRun} from '@app/models/model-run.model';
import {Subscription} from 'rxjs';
import {ScenarioService} from '@app/services/scenario.service';
import {UiBlockerService} from '@app/services/ui-blocker.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ModelRunService} from '@app/services/model-run.service';
import {delay, filter, repeatWhen, take} from 'rxjs/operators';
import {PriceChangeImpactReport} from '@app/models/price-change-impact-report.model';
import {PriceChangeImpactService} from '@app/services/price-change-impact.service';
import {GenericConfirmationModalComponent} from '@app/components/generic-confirmation-modal/generic-confirmation-modal.component';
import {SnackBarService} from '@app/components/snack-bar/snack-bar.service';
import {MetaData} from '@app/models/meta-data.model';

@Component({
  selector: 'app-list-price-change-impact',
  templateUrl: './list-price-change-impact.component.html',
  styleUrls: ['./list-price-change-impact.component.scss']
})
export class ListPriceChangeImpactComponent implements OnInit, OnDestroy {

  modelRun: ModelRun;

  reports: Array<PriceChangeImpactReport>;

  subscriptions: Subscription;

  metaData: MetaData;

  get projectId(): number {
    return this.modelRun.projectId;
  }

  get modelRunId(): string {
    return this.modelRun.id;
  }

  constructor(private route: ActivatedRoute,
              private priceChangeImpactService: PriceChangeImpactService,
              private scenarioService: ScenarioService,
              private router: Router,
              private uiBlockerService: UiBlockerService,
              private dialog: MatDialog,
              private modelRunService: ModelRunService,
              private snackBarService: SnackBarService,
              private ngZone: NgZone) {
  }

  ngOnInit(): void {
    this.metaData = this.route.parent.parent.snapshot.data.metaData;
    this.subscriptions = new Subscription();
    /**
     * Angular nuances, when parent route(reports) param changes we need to watch it and reset the data accordingly.
     * I am not sure why it just doesn't re-render the route like in Ember.
     */
    this.subscriptions.add(this.route.parent.parent.params.subscribe(() => {
      this.uiBlockerService.block();
      this.reports = [];
      this.modelRun = this.modelRunService.activeModelRun;
      this.priceChangeImpactService.getAll(this.projectId, this.modelRunId).subscribe((reports: PriceChangeImpactReport[]) => {
        this.reports = reports;
        this.uiBlockerService.unblock();
        if (reports.length > 0) {
          this.ngZone.runOutsideAngular(() => {
            this.setupStatusPolling();
          });
        }
      });
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Sets up a polling mechanism to fetch and update reports data is there are any runs which
   * have not completed.
   * @see https://stackoverflow.com/questions/51640489/rxjs-repeat-query-until-a-condition-is-met
   */
  setupStatusPolling(): void {
    const projectId = this.projectId;
    const modelRunId = this.modelRunId;

    this.subscriptions.add(this.priceChangeImpactService.fetchAll(projectId, modelRunId).pipe(
      repeatWhen(obs => obs.pipe(delay(10000))),
      filter(reports => {
        const allRunsCompleted = reports.every((report) => {
          return ['Completed', 'Failed'].indexOf(report.status) !== -1;
        });
        return allRunsCompleted;
      }), take(1)
    ).subscribe(reports => this.ngZone.run(() => {
      this.reports = reports;
    })));
  }

  createPriceChangeImpactRun(): void {
    this.router.navigate(['create'], {relativeTo: this.route.parent});
  }

  /**
   * Downloads optimization report file.
   * @see https://stackoverflow.com/questions/51960172/set-file-name-while-downloading-via-blob-in-angular-5
   */
  downloadReport(report: PriceChangeImpactReport): void {
    this.uiBlockerService.block();
    this.priceChangeImpactService.generateReport(report).subscribe((response) => {
      const blob = new Blob([response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.download = `${report.name}.xlsx`;
      anchor.href = url;
      anchor.click();
      this.uiBlockerService.unblock();
    }, error => {
      this.uiBlockerService.unblock();
      this.snackBarService.openErrorSnackBar('Failed downloading report.');
    });
  }

  deleteReport(report: PriceChangeImpactReport): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '800px';
    dialogConfig.data = {
      header: 'Delete?',
      body: 'Are you sure you want to delete the report?',
      cancelButtonLabel: 'CANCEL',
      confirmButtonLabel: 'DELETE'
    };

    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(value => {
      if (value === 'DELETE') {
        this.uiBlockerService.block();
        this.priceChangeImpactService.delete(report).subscribe(() => {
          this.uiBlockerService.unblock();
          this.reports = [...this.priceChangeImpactService.priceChangeImpactReports];
        }, error => {
          this.uiBlockerService.unblock();
        });
      }
    });
  }

  duplicate(report: PriceChangeImpactReport) {
    this.router.navigate(['create'], {
      relativeTo: this.route.parent,
      queryParams: { source: report.id }
    });
  }
}
