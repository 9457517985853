import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {EnvironmentService} from './../services/environment.service';
import {HttpClient} from '@angular/common/http';
import {PriceElasticityReport} from '../models/price-elasticity-report.model';
import {ModelCacheUtil} from "@app/utils/model-cache-util";
import {UtilService} from '@app/services/util.service';

@Injectable({
  providedIn: 'root'
})
export class PriceElasticityService {

  /**
   * Cached list of optimization reports
   */
  private cache: ModelCacheUtil<PriceElasticityReport>;

  constructor(private http: HttpClient, private environmentService: EnvironmentService, private utilService: UtilService) {
    this.cache = new ModelCacheUtil<PriceElasticityReport>();
  }

  get priceElasticityReports(): Array<PriceElasticityReport> {
    return this.cache.cachedModels;
  }

  getNewReportName(): string {
    const cachedReports = this.cache.cachedModels;
    const prependName = 'Untitled Price Elasticity';
    let count = 0;
    let newName = `${prependName} ${count}`;
    let loop = true;
    while (loop) {
      if (cachedReports.find((report: PriceElasticityReport) => {
        return report.name.toLowerCase().trim() === newName.toLowerCase().trim();
      })) {
        newName = `${prependName} ${++count}`;
      } else {
        loop = false;
      }
    }
    return newName;
  }

  getDuplicateReportName(prependName: string, reportNameMaxLength: number): string {
    const cachedReports = this.cache.cachedModels;
    return this.utilService.generateDuplicateReportName(prependName, reportNameMaxLength, cachedReports);
  }

  fetchAll(projectId: number, modelRunId: string): Observable<Array<PriceElasticityReport>> {
    const env = this.environmentService.environment.authProxy;
    const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${projectId}/runs/${modelRunId}/priceElasticityReports`;
    return this.http.get<Array<PriceElasticityReport>>(url).pipe(map((multipleReports: PriceElasticityReport[]) => {
      this.cache.appendAll(multipleReports);
      return multipleReports;
    }));
  }

  /**
   * Lists all price elasticity reports associated with given project and run id.
   */
  getAll(projectId: number, modelRunId: string): Observable<Array<PriceElasticityReport>> {
    const reports = this.cache.cachedModels.filter((it: PriceElasticityReport) => {
      return it.projectId === projectId && it.modelRunId === modelRunId;
    });
    if (reports.length) {
      return of(reports);
    } else {
      return this.fetchAll(projectId, modelRunId);
    }
  }

  /**
   * Returns single price elasticity report associated with given project and run id.
   */
  getById(id: string, params: { projectId: number; modelRunId: string }): Observable<PriceElasticityReport> {
    const report = this.cache.cachedModels.find((it: PriceElasticityReport) => {
      return it.id === id;
    });
    if (report) {
      return of(report);
    } else {
      const env = this.environmentService.environment.authProxy;
      const contextPath = `${env.url}/${env.lpoSimulatorContextPath}`;
      const url = `${contextPath}/projects/${params.projectId}/runs/${params.modelRunId}/priceElasticityReports/${id}`;
      return this.http.get<PriceElasticityReport>(url).pipe(map((singleReport: PriceElasticityReport) => {
        this.cache.append(singleReport);
        return singleReport;
      }));
    }
  }

  /**
   * Generates price elasticity report associated with given project and run id.
   */
  add(priceElasticityReport: PriceElasticityReport): Observable<PriceElasticityReport> {
    const env = this.environmentService.environment.authProxy;
    const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${priceElasticityReport.projectId}/runs/${priceElasticityReport.modelRunId}/priceElasticityReports`;
    return this.http.post<PriceElasticityReport>(url, {priceElasticityReport}).pipe(map((singleReport: PriceElasticityReport) => {
      this.cache.append(singleReport);
      return singleReport;
    }));
  }

  delete(priceElasticityReport: PriceElasticityReport): Observable<string> {
    const env = this.environmentService.environment.authProxy;
    const projectId = priceElasticityReport.projectId;
    const modelRunId = priceElasticityReport.modelRunId;
    const id = priceElasticityReport.id;
    const contextPath = `${env.url}/${env.lpoSimulatorContextPath}`;
    const url = `${contextPath}/projects/${projectId}/runs/${modelRunId}/priceElasticityReports/${id}`;
    return this.http.delete<PriceElasticityReport>(url).pipe(map(() => {
      this.cache.remove(priceElasticityReport);
      return priceElasticityReport.id;
    }));
  }

  /**
   * Method is use to download file.
   * @param data - Array Buffer data
   * @param type - type of the document.
   */
  generateReport(report: PriceElasticityReport): Observable<any> {
    const env = this.environmentService.environment.authProxy;
    const projectId = report.projectId;
    const modelRunId = report.modelRunId;
    const id = report.id;
    const contextPath = `${env.url}/${env.lpoSimulatorContextPath}`;
    const url = `${contextPath}/projects/${projectId}/runs/${modelRunId}/priceElasticityReports/${id}/download`;
    return this.http.get(`${url}`, {
        responseType: 'arraybuffer'
      }
    );
  }

  refreshCachedModel(updatedData: any): PriceElasticityReport {
    if (updatedData) {
      const updatedModel = <PriceElasticityReport>updatedData;
      return this.cache.refresh(updatedModel);
    }
    return null;
  }
}
