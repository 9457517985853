<div class="create-list">
    <div class="table-data">
        <table>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Run Name</th>
                    <th>Run Description</th>
                    <th>Scenario</th>
                    <th>Sample Information</th>
                    <th>Item Characteristic</th>
                    <th>Metric(s)</th>
                    <th>Status</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let report of reports; let i = index;">
                    <td>{{i+1}}</td>
                    <td>{{report.name}}</td>
                    <td>{{report.description}}</td>
                    <td>{{report.scenarioId | getScenarioName:projectId:modelRunId}}</td>
                    <td [appReportSampleInfo]="report"></td>
                    <td>{{report.skuGroupId | getGroupName:projectId:modelRunId}}</td>
                    <td class="metrics">{{report.metrics | getMetricDisplayName:metaData}}</td>
                    <td [appReportStatus]="report"></td>
                    <td>
                        <button class="delete-btn" mat-icon-button aria-label="Delete Price Change Impact Report" (click)="deleteReport(report)"><span class="sif sif-delete"></span></button>
                        <button class="download-btn" mat-icon-button aria-label="Download Price Change Impact Report" (click)="downloadReport(report)" [disabled]="report.status!=='Completed'? true : null"><span class="sif sif-download"></span></button>
                        <button class="copy-btn" mat-icon-button aria-label="Copy Price Change Impact Report" (click)="duplicate(report)"><span class="sif sif-copy"></span></button>
                    </td>
                </tr>
                <tr *ngIf="reports.length===0">
                    <td colspan="6" class="no-optimize">No Price Change Impact Runs currently exist. Press “+ Price Change Impact Run” to begin adding runs to the queue.</td>
                </tr>
            </tbody>
        </table>
    </div>
    <button mat-button (click)="createPriceChangeImpactRun()" class="add-report"><span class="sif sif-add"></span><span>Price Change Impact RUN</span></button>
</div>
